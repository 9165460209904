import { retrokitApi } from "common/api/retrokitApi";

import type {
  GetProjectReportConfigRequest,
  UpdateProjectReportConfigRequest
} from "./types";
import type { ProjectReportConfig } from "../types";


const projectReportConfigApiEndpoint = `integrations/project/report-config`

export const projectReportConfigApi = retrokitApi.injectEndpoints({
  endpoints: (builder) => ({

    // See documentation here: https://documentation.retrokit.eu/books/retrokit-api/page/get-project-report-configuration
    getProjectReportConfig: builder.query<ProjectReportConfig, GetProjectReportConfigRequest>({
      query: (arg) => ({
        url: projectReportConfigApiEndpoint,
        method: "POST",
        body: arg
      }),
      providesTags: [
        "ProjectReportConfig"
      ]
    }),

    // See documentation here: https://documentation.retrokit.eu/books/retrokit-api/page/update-project-report-configuration
    updateProjectReportConfig: builder.mutation<ProjectReportConfig, UpdateProjectReportConfigRequest>({
      query: (arg) => ({
        url: projectReportConfigApiEndpoint,
        method: "PUT",
        body: arg
      }),
      invalidatesTags: [
        "ProjectReportConfig"
      ]
    }),

  })
});

export const {
  useGetProjectReportConfigQuery,
  useUpdateProjectReportConfigMutation
} = projectReportConfigApi;