import { useCallback, useState } from "react";

import { useLocation } from "react-router-dom";

import Box from "@mui/material/Box";

import {
  HomeEnergyAssessmentProvider
} from "features/homeEnergyAssessment/hooks/useHomeEnergyAssessment";
import { NoProjectsView } from "features/projects/components/NoProjectsView";
import { ProjectsDrawer } from "features/projects/components/ProjectsDrawer";
import { ProjectView } from "features/projects/components/ProjectView";
import {
  CreateProjectDialog
} from "features/projects/dialogs/CreateProjectDialog";
import { useProjectsPage } from "features/projects/hooks/useProjectsPage";

import type { CreateProjectApiRequest } from "features/projects/api/types";
import type { MinimalProject } from "features/projects/types";


type ProjectsPageProps = {
  projectsMenuShowing: boolean;
  setProjectsMenuShowing: (showing: boolean) => void;
};


export function ProjectsPage(
  {
    projectsMenuShowing,
    setProjectsMenuShowing
  }: ProjectsPageProps) {

  const location = useLocation();

  const {
    projects,
    createProject,
    deleteProject
  } = useProjectsPage();

  const locationState = location.state as {
    newProject: MinimalProject
  };

  const [
    selectedProject, setSelectedProject
  ] = useState<MinimalProject | undefined>(
    locationState?.newProject || undefined
  );

  const [
    isAddProjectOpen, setIsAddProjectOpen
  ] = useState<boolean>(false);

  const onProjectViewClicked = () => {
    if (projectsMenuShowing) {
      setProjectsMenuShowing(false);
    }
  };

  const handleOnCreateProject = useCallback(
    (requestArgs: CreateProjectApiRequest) => {
      createProject(requestArgs);
    },
    [
      createProject
    ]
  );

  const handleOnDeleteProject = useCallback(
    () => {
      if (selectedProject) {
        setSelectedProject(undefined);
        deleteProject({
          id: selectedProject.id
        });
        setProjectsMenuShowing(true);
      }
    },
    [
      deleteProject,
      selectedProject,
      setProjectsMenuShowing
    ]
  );

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        padding: 0,
        margin: 0,
        display: "flex",
        flexDirection: "row"
      }}
    >

      <ProjectsDrawer
        onAddProjectClick={() => setIsAddProjectOpen(true)}
        onClose={() => setProjectsMenuShowing(false)}
        open={projectsMenuShowing}
        projects={projects}
        setSelectedProject={setSelectedProject}
      />

      {
        selectedProject !== undefined ? <HomeEnergyAssessmentProvider>
            <ProjectView
              minimalProject={selectedProject}
              onDeleteProject={handleOnDeleteProject}
              onViewClicked={onProjectViewClicked}
            />
          </HomeEnergyAssessmentProvider> : <NoProjectsView
            onViewClicked={onProjectViewClicked}
          />
      }

      <CreateProjectDialog
        open={isAddProjectOpen}
        onClose={() => setIsAddProjectOpen(false)}
        onCreateProject={(data) => {
          handleOnCreateProject(data);
          setIsAddProjectOpen(false);
        }}
      />

    </Box>
  );
}
