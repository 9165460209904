import React, { useState } from "react";

import { format } from "date-fns";

import {
  Add as AddIcon,
  Stop as StopIcon
} from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";

import type { MinimalProject } from "features/projects/types";


type ProjectDrawerProps = {
  onAddProjectClick: () => void;
  onClose: () => void;
  open: boolean;
  projects: Array<MinimalProject> | undefined;
  setSelectedProject: (project: MinimalProject) => void;
};

const drawerWidth = 240;


export function ProjectsDrawer(
  {
    onAddProjectClick,
    onClose,
    open,
    projects,
    setSelectedProject
  }: ProjectDrawerProps) {

  const [
    selectedIndex,
    setSelectedIndex
  ] = useState<number>();

  const handleProjectSelected = (project: MinimalProject) => {
    setSelectedIndex(project.id);
    setSelectedProject(project);
  };

  const handleDrawerClose = () => {
    onClose();
  };

  return <Collapse
    orientation="horizontal"
    in={open}
    sx={{
      zIndex: 1150,
      position: "absolute",
      height: "100%"
    }}
  >
    <Box
      sx={{
        height: "100%",
        width: drawerWidth,
        flexShrink: 0,
        backgroundColor: "#FFFFFF",
        borderRight: "1px solid #dadce0"
      }}
    >

      <MenuItem
        key="text"
        disabled
        sx={{
          fontWeight: 600,
          fontSize: 16,
          opacity: 1
        }}
      >
        Projects
      </MenuItem>

      <Box
        key="spacer-1"
        sx={{
          background: "#dadce0",
          height: "1px",
          width: "100%"
        }}
      />

      <List
        sx={{
          marginBottom: "52px"
        }}
      >
        <ListItem
          disablePadding
          key="0"
        >
          <ListItemButton>
            <Tooltip
              title="Create a new project"
              placement="right"
            >
              <Button
                sx={{
                  width: "100%"
                }}
                variant="outlined"
                color="secondary"
                endIcon={<AddIcon />}
                onClick={() => onAddProjectClick()}
              >
                Create
              </Button>
            </Tooltip>
          </ListItemButton>
        </ListItem>

        {
          projects !== undefined ? projects.slice().sort((projectA, projectB) => {
            if (projectA.title.toLowerCase() < projectB.title.toLowerCase()) {
              return -1;
            }
            if (projectA.title.toLowerCase() > projectB.title.toLowerCase()) {
              return 1;
            }
            return 0;
          }).map((project) => (
            <Tooltip
              key={project.id}
              title={project.description}
              placement="right"
            >
              <ListItem
                disablePadding
                onClick={() => {
                  handleProjectSelected(project);
                  handleDrawerClose();
                }}
              >
                <ListItemButton
                  selected={project.id === selectedIndex}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <StopIcon />
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primary={project.title}
                    secondary={
                      String(format(
                        project.created_on,
                        "do MMM yyyy"
                      ))}
                  />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          )) : null
        }

      </List>
    </Box>

  </Collapse>;
}
