import React, { ChangeEvent, MouseEvent } from "react";
import { useCallback, useState } from "react";

import { toast } from "react-toastify";

import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Paper, { type PaperProps } from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import type { Dwelling } from "features/dwellings/types";
import type { ProjectScenario } from "features/projectScenarios/types";
import type {
  GenerateHUP1Request,
  GenerateHUP2Request
} from "features/reports/api/types";
import type { MinimalReport } from "features/reports/types";


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof never>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string | Date },
  b: { [key in Key]: number | string | Date }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface Report {
  id: number;
  title: string;
  description: string;
}

interface ReportHeadCell {
  disablePadding: boolean;
  id: keyof Report;
  label: string;
  numeric: boolean;
}

const reportHeadCells: readonly ReportHeadCell[] = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: ""
  },
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "Title"
  },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description"
  }
];

interface ScenarioHeadCell {
  disablePadding: boolean;
  id: Exclude<keyof ProjectScenario,
    | "related_performances"
    | "recommended_systems"
    | "measures"
    | "custom_measures">;
  label: string;
  numeric: boolean;
}

const scenarioHeadCells: readonly ScenarioHeadCell[] = [
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "Title"
  },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description"
  }
];

interface GenerateReportModalProps {
  open: boolean;
  dwelling: Dwelling;
  generateHEAReport: () => void;
  generateHUP1Report: (
    data: Pick<GenerateHUP1Request,
      "dwellingsList" | "scenariosList" | "reportId">
  ) => void;
  generateHUP2Report: (
    data: Pick<GenerateHUP2Request,
      | "dwellingsList"
      | "scenariosList"
      | "reportId"
      | "recommendedScenario"
      | "showAllScenarios">
  ) => void;
  hea1Reports: Array<MinimalReport>;
  hea2Reports: Array<MinimalReport>;
  scenarios: ReadonlyArray<ProjectScenario>;
  onClose: () => void;
}

export function GenerateReportDialog(
  {
    open,
    dwelling,
    scenarios,
    hea1Reports,
    hea2Reports,
    generateHEAReport,
    generateHUP1Report,
    generateHUP2Report,
    onClose
  }: GenerateReportModalProps) {

  const theme = useTheme();

  const [accordionExpanded, setAccordionExpanded] = useState<string | false>(
    "chooseReportPanel"
  );

  const [reportOrder, setReportOrder] = useState<Order>("asc");
  const [reportOrderBy, setReportOrderBy] = useState<keyof Report>("title");
  const [selectedReport, setSelectedReport] = useState(0);

  const [scenarioOrder, setScenarioOrder] = useState<Order>("asc");
  const [scenarioOrderBy, setScenarioOrderBy] =
    useState<Exclude<keyof ProjectScenario,
      | "related_performances"
      | "recommended_systems"
      | "measures"
      | "custom_measures">>("title");
  const [
    selectedScenarios, setSelectedScenarios
  ] = useState<readonly ProjectScenario[]>([]);

  const [
    recommendedScenario, setRecommendedScenario
  ] = useState<ProjectScenario>({
    id: 0,
    title: "",
    description: "",
    created_on: new Date(),
    related_performances: [],
    measures: [],
    custom_measures: [],
    recommended_systems: [],
    root_scenario: "",
    type: ""
  });

  const [showScenarios, setShowScenarios] = useState<boolean>(true);

  const handleClose = () => {
    setSelectedScenarios([]);
    setRecommendedScenario({
      id: 0,
      title: "",
      description: "",
      created_on: new Date(),
      related_performances: [],
      measures: [],
      custom_measures: [],
      recommended_systems: [],
      root_scenario: "",
      type: ""
    });
    setShowScenarios(true);
    onClose();
  };

  // REPORTS
  const handleReportTableRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof Report
  ) => {
    const isAsc = reportOrderBy === property && reportOrder === "asc";
    setReportOrder(isAsc ? "desc" : "asc");
    setReportOrderBy(property);
  };

  const createReportTableSortHandler =
    (property: keyof Report) => (event: MouseEvent<unknown>) => {
      handleReportTableRequestSort(event, property);
    };

  const handleReportTableClick = (
    event: MouseEvent<unknown>,
    report: Report
  ) => {
    setSelectedReport(report.id);
  };

  const isReportSelected = (report: Report) => selectedReport === report.id;

  // SCENARIOS
  const handleScenarioTableRequestSort = (
    event: MouseEvent<unknown>,
    property: Exclude<keyof ProjectScenario,
      | "related_performances"
      | "recommended_systems"
      | "measures"
      | "custom_measures">
  ) => {
    const isAsc = scenarioOrderBy === property && scenarioOrder === "asc";
    setScenarioOrder(isAsc ? "desc" : "asc");
    setScenarioOrderBy(property);
  };

  const createScenarioTableSortHandler =
    (
      property: Exclude<keyof ProjectScenario,
        | "related_performances"
        | "recommended_systems"
        | "measures"
        | "custom_measures">
    ) =>
      (event: MouseEvent<unknown>) => {
        handleScenarioTableRequestSort(event, property);
      };

  const handleScenarioTableSelectAllClick = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      setSelectedScenarios(scenarios);
      return;
    }
    setSelectedScenarios([]);
  };

  const handleScenarioTableClick = (
    event: MouseEvent<unknown>,
    scenario: ProjectScenario
  ) => {
    const selectedIndex = selectedScenarios.indexOf(scenario);
    let newSelected: readonly ProjectScenario[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedScenarios, scenario);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedScenarios.slice(1));
    } else if (selectedIndex === selectedScenarios.length - 1) {
      newSelected = newSelected.concat(selectedScenarios.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedScenarios.slice(0, selectedIndex),
        selectedScenarios.slice(selectedIndex + 1)
      );
    }

    if (
      recommendedScenario.id !== 0 &&
      !newSelected.includes(recommendedScenario)
    ) {
      setRecommendedScenario({
        id: 0,
        title: "",
        description: "",
        created_on: new Date(),
        related_performances: [],
        measures: [],
        custom_measures: [],
        recommended_systems: [],
        root_scenario: "",
        type: ""
      });
    }

    setSelectedScenarios(newSelected);
  };

  const isScenarioSelected = (scenario: ProjectScenario) =>
    selectedScenarios.indexOf(scenario) !== -1;

  // RECOMMENDED SCENARIO
  const handleRecommendedScenarioTableClick = (
    event: MouseEvent<unknown>,
    scenario: ProjectScenario
  ) => {
    setRecommendedScenario(scenario);
  };

  const isScenarioRecommended = (scenario: ProjectScenario) =>
    recommendedScenario === scenario;

  // REPORTS
  const handleGenerateHEAReport = useCallback(() => {
    generateHEAReport();
  }, [
    generateHEAReport
  ]);

  const handleGenerateHUP1Report = useCallback(() => {
    generateHUP1Report({
      dwellingsList: [{"id": dwelling.id, "type": dwelling.type}],
      scenariosList: selectedScenarios.map((scenario) => `${scenario.id}`),
      reportId: `${hea1Reports[0].id}`
    });
  }, [
    dwelling,
    generateHUP1Report,
    hea1Reports,
    selectedScenarios
  ]);

  const handleGenerateHUP2Report = useCallback(() => {
    generateHUP2Report({
      dwellingsList: [{"id": dwelling.id, "type": dwelling.type}],
      scenariosList: selectedScenarios.map((scenario) => `${scenario.id}`),
      recommendedScenario: `${recommendedScenario.id}`,
      reportId: `${hea2Reports[0].id}`,
      showAllScenarios: showScenarios ? "True" : "False"
    });
  }, [
    dwelling,
    generateHUP2Report,
    hea2Reports,
    recommendedScenario,
    selectedScenarios,
    showScenarios
  ]);

  const handleGenerateReport = () => {
    if (selectedReport === 1) {
      handleGenerateHUP1Report();
      handleClose();
    } else if (selectedReport === 2) {
      handleGenerateHUP2Report();
      handleClose();
    } else if (selectedReport === 3) {
      handleGenerateHEAReport();
      handleClose();
    } else {
      toast.error("No report type selected", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored"
      });
    }
  };

  const handleAccordionChange = (panel: string, isExpanded: boolean) => {
    setAccordionExpanded(isExpanded ? panel : false);
  };

  const reports = Array<Report>(
    {
      id: 1,
      title: "HEA 1",
      description: "Simplified home energy assessment"
    },
    {
      id: 2,
      title: "HEA 2",
      description: "Detailed home energy assessment"
    }
    // {
    //   id: 3,
    //   title: "SEAI HEA",
    //   description: "SEAI detailed home energy assessment"
    // }
  );

  const scenarioResultsExistForEveryDwelling = scenarios.every((scenario) =>
    scenario.related_performances.some(
      (performance) => dwelling.id === performance.dwelling
    )
  );

  const DialogPaperComponent = useCallback(
    (
      props: JSX.IntrinsicAttributes & PaperProps
    ) => <Paper
      elevation={0}
      sx={{
        borderWidth: "0px",
        borderRadius: "20px"
      }}
      variant="outlined"
      {...props}
    />,
    []
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      scroll="body"
      PaperComponent={DialogPaperComponent}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          paddingTop: "8px",
          paddingBottom: "8px",
          paddingLeft: "24px",
          paddingRight: "8px"
        }}
      >
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
          variant="h5"
        >
          Configure Report
        </Typography>
        <Box
          sx={{
            flex: 1
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row"
          }}
        >
          {
            handleClose ? (
              <Tooltip
                title="Close"
                aria-label="pdf-close-modal"
              >
                <IconButton
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            ) : null
          }
        </Box>
      </Box>

      <DialogContent
        sx={{
          width: "100%",
          flex: 1,
          overflowX: "hidden",
          overflowY: "auto"
        }}
      >
        <Paper
          sx={{
            display: "flex",
            padding: "0px 16px",
            minHeight: "48px"
          }}
          elevation={0}
          variant="outlined"
        >
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              margin: "20px 0"
            }}
          >
            <Typography
              sx={{
                width: "31%",
                flexShrink: 0
              }}
            >
              Dwelling
            </Typography>

            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "row"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  paddingRight: "16px"
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.text.secondary
                  }}
                >
                  MPRN: {dwelling.mprn}
                </Typography>

                {dwelling.street !== "None" &&
                dwelling.street !== "" ? (
                  <Typography
                    sx={{
                      color: theme.palette.text.secondary
                    }}
                  >
                    {dwelling.street},
                  </Typography>
                ) : null}

                {dwelling.area !== "None" &&
                dwelling.area !== "" ? (
                  <Typography
                    sx={{
                      color: theme.palette.text.secondary
                    }}
                  >
                    {dwelling.area},
                  </Typography>
                ) : null}

                {dwelling.city !== "None" &&
                dwelling.city !== "" ? (
                  <Typography
                    sx={{
                      color: theme.palette.text.secondary
                    }}
                  >
                    {dwelling.city},
                  </Typography>
                ) : null}

                {dwelling.county !== "None" &&
                dwelling.county !== "" ? (
                  <Typography
                    sx={{
                      color: theme.palette.text.secondary
                    }}
                  >
                    {dwelling.county},
                  </Typography>
                ) : null}

                {dwelling.postcode !== "None" &&
                dwelling.postcode !== "" ? (
                  <Typography
                    sx={{
                      color: theme.palette.text.secondary
                    }}
                  >
                    {dwelling.postcode}
                  </Typography>
                ) : null}
              </Box>

              <Box
                sx={{
                  width: 158.4,
                  height: 144
                }}
              >
                <img
                  src={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/geojson(%7B%22type%22%3A%22Point%22%2C%22coordinates%22%3A%5B${dwelling.latitude_longitude.coordinates[0]}%2C${dwelling.latitude_longitude.coordinates[1]}%5D%7D)/${dwelling.latitude_longitude.coordinates[0]},${dwelling.latitude_longitude.coordinates[1]},12/158x144?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || "Not set"}`}
                  alt="Static Mapbox map of the dwelling location"
                />
              </Box>
            </Box>
          </Box>
        </Paper>

        <Accordion
          sx={{
            width: "550px"
          }}
          expanded={accordionExpanded === "chooseReportPanel"}
          onChange={(event, isExpanded) => {
            handleAccordionChange("chooseReportPanel", isExpanded);
          }}
          elevation={0}
          variant="outlined"
        >
          <AccordionSummary
            id="chooseReportPanel-header"
            expandIcon={
              <Tooltip
                title="Expand"
                aria-label="chooseReportPanel-expand"
              >
                <ExpandMoreIcon />
              </Tooltip>
            }
            aria-controls="chooseReportPanel-content"
          >
            <Typography
              sx={{
                width: "33%",
                flexShrink: 0
              }}
            >
              Type
            </Typography>

            {selectedReport === 0 ? (
              <Typography
                sx={{
                  color: theme.palette.error.dark
                }}
              >
                Choose a report type
              </Typography>
            ) : null}

            {selectedReport === 1 ? (
              <Typography
                sx={{
                  color: theme.palette.success.dark
                }}
              >
                HEA 1
              </Typography>
            ) : null}

            {selectedReport === 2 ? (
              <Typography
                sx={{
                  color: theme.palette.success.dark
                }}
              >
                HEA 2
              </Typography>
            ) : null}
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="medium"
              >
                <TableHead>
                  <TableRow>
                    {reportHeadCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={
                          reportOrderBy === headCell.id ? reportOrder : false
                        }
                      >
                        <TableSortLabel
                          active={reportOrderBy === headCell.id}
                          direction={
                            reportOrderBy === headCell.id ? reportOrder : "asc"
                          }
                          onClick={createReportTableSortHandler(headCell.id)}
                        >
                          {headCell.label}
                          {reportOrderBy === headCell.id ? (
                            <Box
                              sx={{
                                border: 0,
                                clip: "rect(0 0 0 0)",
                                height: "1px",
                                margin: -1,
                                overflow: "hidden",
                                padding: 0,
                                position: "absolute",
                                whiteSpace: "nowrap",
                                width: "1px"
                              }}
                              component="span"
                            >
                              {reportOrder === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reports
                    .slice()
                    .sort(getComparator(reportOrder, reportOrderBy))
                    .map((row, index) => {
                      const isSelected = isReportSelected(row);
                      const labelId = `report-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) =>
                            handleReportTableClick(event, row)
                          }
                          role="checkbox"
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={row.title}
                          selected={isSelected}
                        >
                          <TableCell
                            padding="checkbox"
                          >
                            <Checkbox
                              color="secondary"
                              checked={isSelected}
                              inputProps={{
                                "aria-labelledby": labelId
                              }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            <Typography>
                              {row.title}
                            </Typography>
                          </TableCell>

                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            <Typography
                              sx={{
                                color: theme.palette.text.secondary
                              }}
                            >
                              {row.description}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>

        {
          selectedReport !== 3 ? <Tooltip
            title={
              // eslint-disable-next-line no-nested-ternary
              scenarios.some(
                (scenario) => scenario.related_performances.length === 0
              )
                ? "There are no scenario results. Run the project first"
                : scenarioResultsExistForEveryDwelling
                  ? ""
                  : "Scenario results do not exist for the dwelling selection. Run the project first"
            }
            aria-label="scenario-selection-table-row"
          >
            <Accordion
              sx={{
                width: "550px"
              }}
              disabled={
                selectedReport === 0 || !scenarioResultsExistForEveryDwelling
              }
              expanded={accordionExpanded === "chooseScenarioPanel"}
              onChange={(event, isExpanded) => {
                handleAccordionChange("chooseScenarioPanel", isExpanded);
              }}
              elevation={0}
              variant="outlined"
            >
              <AccordionSummary
                id="chooseScenarioPanel-header"
                expandIcon={
                  <Tooltip
                    title="Expand"
                    aria-label="chooseScenarioPanel-expand"
                  >
                    <ExpandMoreIcon />
                  </Tooltip>
                }
                aria-controls="chooseScenarioPanel-content"
              >
                {
                  selectedScenarios.length < 2 ? (
                    <Typography
                      sx={{
                        width: "33%",
                        flexShrink: 0
                      }}
                    >
                      Scenario
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        width: "33%",
                        flexShrink: 0
                      }}
                    >
                      Scenarios
                    </Typography>
                  )
                }

                {
                  selectedScenarios.length === 0 ||
                  !scenarioResultsExistForEveryDwelling ? (
                    <Typography
                      sx={
                        selectedReport === 0
                          ? {
                            color: theme.palette.text.secondary
                          }
                          : {
                            color: theme.palette.error.dark
                          }
                      }
                    >
                      {
                        scenarioResultsExistForEveryDwelling
                          ? "Select scenarios to be included"
                          : "No scenario results for chosen dwelling"
                      }
                    </Typography>
                  ) : null
                }

                {selectedScenarios.length > 0 &&
                scenarioResultsExistForEveryDwelling ? (
                  <Typography sx={{
                    color: theme.palette.success.dark
                  }}
                  >
                    {selectedScenarios
                      .map((scenario) => scenario.title)
                      .join(", ")}
                  </Typography>
                ) : null}
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer>
                  <Table
                    aria-labelledby="tableTitle"
                    size="medium"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          padding="checkbox"
                        >
                          <Checkbox
                            color="secondary"
                            indeterminate={
                              selectedScenarios.length > 0 &&
                              selectedScenarios.length < scenarios.length
                            }
                            checked={
                              scenarios.length > 0 &&
                              selectedScenarios.length === scenarios.length
                            }
                            onChange={handleScenarioTableSelectAllClick}
                            inputProps={{
                              "aria-label": "Select all scenarios"
                            }}
                          />
                        </TableCell>
                        {scenarioHeadCells.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                            sortDirection={
                              scenarioOrderBy === headCell.id
                                ? scenarioOrder
                                : false
                            }
                          >
                            <TableSortLabel
                              active={scenarioOrderBy === headCell.id}
                              direction={
                                scenarioOrderBy === headCell.id
                                  ? scenarioOrder
                                  : "asc"
                              }
                              onClick={createScenarioTableSortHandler(
                                headCell.id
                              )}
                            >
                              {headCell.label}
                              {scenarioOrderBy === headCell.id ? (
                                <Box
                                  sx={{
                                    border: 0,
                                    clip: "rect(0 0 0 0)",
                                    height: "1px",
                                    margin: -1,
                                    overflow: "hidden",
                                    padding: 0,
                                    position: "absolute",
                                    whiteSpace: "nowrap",
                                    width: "1px"
                                  }}
                                  component="span"
                                >
                                  {scenarioOrder === "desc"
                                    ? "sorted descending"
                                    : "sorted ascending"}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {scenarios
                        .slice()
                        .sort(getComparator(scenarioOrder, scenarioOrderBy))
                        .map((row, index) => {
                          const isSelected = isScenarioSelected(row);
                          const labelId = `scenario-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={(event) =>
                                handleScenarioTableClick(event, row)
                              }
                              role="checkbox"
                              aria-checked={isSelected}
                              tabIndex={-1}
                              key={row.title}
                              selected={isSelected}
                            >
                              <TableCell
                                padding="checkbox"
                              >
                                <Checkbox
                                  color="secondary"
                                  checked={isSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId
                                  }}
                                  disabled={row.related_performances.length === 0}
                                />
                              </TableCell>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                sx={{
                                  width: "21.5%"
                                }}
                              >
                                {row.title}
                              </TableCell>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                {row.description}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Tooltip> : null
        }


        {
          selectedReport === 2 ? (
            <Accordion
              sx={{
                width: "550px"
              }}
              expanded={accordionExpanded === "recommendedScenarioPanel"}
              onChange={(event, isExpanded) => {
                handleAccordionChange("recommendedScenarioPanel", isExpanded);
              }}
              elevation={0}
              variant="outlined"
              disabled={selectedScenarios.length === 0}
            >
              <AccordionSummary
                id="recommendedScenarioPanel-header"
                expandIcon={
                  <Tooltip
                    title="Expand"
                    aria-label="recommendedScenarioPanel-expand"
                  >
                    <ExpandMoreIcon />
                  </Tooltip>
                }
                aria-controls="recommendedScenarioPanel-content"
              >
                <Typography
                  sx={{
                    width: "33%",
                    flexShrink: 0
                  }}
                >
                  Recommend
                </Typography>

                {
                  recommendedScenario.id === 0 ? (
                    <Typography
                      sx={
                        selectedScenarios.length === 0
                          ? {
                            color: theme.palette.text.secondary
                          }
                          : {
                            color: theme.palette.error.dark
                          }
                      }
                    >
                      Choose the recommended scenario
                    </Typography>
                  ) : null
                }

                {recommendedScenario.id !== 0 ? (
                  <Typography sx={{
                    color: theme.palette.success.dark
                  }}
                  >
                    {recommendedScenario.title}
                  </Typography>
                ) : null}
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer>
                  <Table
                    aria-labelledby="tableTitle"
                    size="medium"
                  >
                    <TableBody>
                      {selectedScenarios
                        .slice()
                        .sort(getComparator(scenarioOrder, scenarioOrderBy))
                        .map((row, index) => {
                          const isRecommended = isScenarioRecommended(row);
                          const labelId = `recommended-scenario-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={(event) =>
                                handleRecommendedScenarioTableClick(event, row)
                              }
                              role="checkbox"
                              aria-checked={isRecommended}
                              tabIndex={-1}
                              key={row.title}
                              selected={isRecommended}
                            >
                              <TableCell
                                padding="checkbox"
                              >
                                <Checkbox
                                  color="secondary"
                                  checked={isRecommended}
                                  inputProps={{
                                    "aria-labelledby": labelId
                                  }}
                                  disabled={row.related_performances.length === 0}
                                />
                              </TableCell>

                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                sx={{
                                  width: "21.5%"
                                }}
                              >
                                {row.title}
                              </TableCell>

                              <TableCell
                                sx={{
                                  width: "21.5%"
                                }}
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                {row.title}
                              </TableCell>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                {row.description}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          ) : null
        }

        {
          selectedReport === 2 ? (
            <Paper
              sx={{
                display: "flex",
                padding: "0px 16px",
                minHeight: "48px"
              }}
              elevation={0}
              variant="outlined"
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1
                }}
              >
                <Checkbox
                  sx={{
                    width: "33%",
                    flexShrink: 0
                  }}
                  checked={showScenarios}
                  onChange={() => setShowScenarios(!showScenarios)}
                />

                {
                  showScenarios ? (
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: theme.palette.text.secondary
                      }}
                    >
                      Show all scenarios
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: theme.palette.text.secondary
                      }}
                    >
                      Show only selected scenarios
                    </Typography>
                  )
                }
              </Box>

              <Box
                sx={{
                  width: "36px"
                }}
              />
            </Paper>
          ) : null
        }


        <Paper
          elevation={0}
          variant="outlined"
          sx={{
            padding: "8px 16px",
            marginTop: "16px"
          }}
        >
          <Typography
            sx={{
              color: theme.palette.text.secondary
            }}
            variant="caption"
          >
            The grant amounts calculated by RetroKit are estimates. These estimates assume that all grant requirements
            have been met. Please ensure that all grant requirements have been met.
          </Typography>
        </Paper>
      </DialogContent>

      <DialogActions
        sx={{
          paddingRight: "16px"
        }}
      >
        <Button
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          onClick={handleGenerateReport}
          disabled={
            selectedReport === 0 ||
            selectedScenarios.length === 0 && (selectedReport === 1 || selectedReport === 2) ||
            (selectedReport === 2 && recommendedScenario.id === 0) ||
            !scenarioResultsExistForEveryDwelling
          }
        >
          Generate
        </Button>
      </DialogActions>
    </Dialog>
  );
}
