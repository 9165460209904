import React, { useCallback, useEffect, useState } from "react";


import {
  colors,
} from "@mui/material";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import {
  BERScaleBeforeAndAfter
} from "common/components/BERScaleBeforeAndAfter";
import iconInANutshell from "common/icons/icon-title-nutshell.png"
import {
  useHomeEnergyAssessment
} from "features/homeEnergyAssessment/hooks/useHomeEnergyAssessment";

import type { Dwelling } from "features/dwellings/types";


interface HomeEnergyAssessmentDialogContent3Props {
  dwelling: Dwelling;
  contentBackgroundColour: string;
  scenarioRating: string;
  inputError: boolean;
  setInputError: React.Dispatch<React.SetStateAction<boolean>>;
  handleHeaFieldBlur: (
    value: string | number | File,
    field: string
  ) => void;
}


export function HomeEnergyAssessmentDialogContent3(
  {
    dwelling,
    contentBackgroundColour,
    scenarioRating,
    inputError,
    setInputError,
    handleHeaFieldBlur
  }: HomeEnergyAssessmentDialogContent3Props
) {

  const {
    homeEnergyAssessment
  } = useHomeEnergyAssessment();

  const [
    sectionHeadingInANutshell,
    setSectionHeadingInANutshell
  ] = useState<string>('');

  const [
    sectionHeadingInANutshellError,
    setSectionHeadingInANutshellError
  ] = useState<boolean>(false);

  const [
    sectionHeadingInANutshellLength,
    setSectionHeadingInANutshellLength
  ] = useState<number>(0);

  const sectionHeadingInANutshellLengthLimit = 100;

  const [
    grantTotal,
    setGrantTotal
  ] = useState<number>(0);

  const theme = useTheme();

  useEffect(
    () => {

      if (sectionHeadingInANutshellError) {
        setInputError(true)
      }

      if (inputError) {
        if (!sectionHeadingInANutshellError) {
          setInputError(false)
        }
      }

    },
    [
      sectionHeadingInANutshellError,
      inputError,
      setInputError
    ]
  );

  useEffect(
    () => {

      if (homeEnergyAssessment !== undefined) {

        setSectionHeadingInANutshell(
          homeEnergyAssessment.section_heading_in_a_nutshell
        )

        setSectionHeadingInANutshellLength(
          homeEnergyAssessment.section_heading_in_a_nutshell !== null ?
            homeEnergyAssessment.section_heading_in_a_nutshell.length : 0
        )

        setGrantTotal(
          homeEnergyAssessment.grant_total +
          homeEnergyAssessment.ber_and_heat_pump_bonus +
          homeEnergyAssessment.central_heating_and_heat_pump_bonus
        )

      }

    },
    [
      homeEnergyAssessment
    ]
  );

  const handleFieldUpdated = useCallback(
    (
      value: string | number | File,
      field: string
    ) => {
      handleHeaFieldBlur(value, field);
    },
    [
      handleHeaFieldBlur
    ]
  );

  return (
    <DialogContent
      sx={{
        width: "100%",
        flex: 1,
        overflowX: "hidden",
        overflowY: "hidden",
        padding: "0px 8px 0px 8px",
        gap: "2rem"
      }}
    >

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            borderRadius: "20px",
            backgroundColor: contentBackgroundColour,
            padding: "16px"
          }}
        >
          <img
            src={iconInANutshell}
            alt="Icon"
            style={{
              maxHeight: "48px"
            }}
          />

          <TextField
            error={sectionHeadingInANutshellError}
            variant="standard"
            fullWidth
            inputProps={{
              style: {
                fontSize: "1.4rem",
                backgroundColor: theme.palette.common.white
              }
            }}
            InputLabelProps={{
              style: {
                fontSize: "1.4rem"
              }
            }}
            FormHelperTextProps={{
              sx: {
                textAlign: "right",
                width: "100%"
              }
            }}
            value={sectionHeadingInANutshell}
            sx={{
              display: "flex",
              alignItems: "center",
              marginLeft: "16px",
              color: colors.grey["800"]
            }}
            onBlur={(event) => {
              if (!inputError) {
                handleFieldUpdated(
                  event.target.value,
                  "section_heading_in_a_nutshell"
                );
              }
            }}
            onChange={(event) => {
              const {
                value
              } = event.target;
              setSectionHeadingInANutshellLength(value.length);
              if (value.length > sectionHeadingInANutshellLengthLimit) {
                setSectionHeadingInANutshellError(true);
              } else if (sectionHeadingInANutshellError) {
                setSectionHeadingInANutshellError(false);
              }
              setSectionHeadingInANutshell(value)
            }}
            helperText={
              sectionHeadingInANutshellLengthLimit - sectionHeadingInANutshellLength <= (sectionHeadingInANutshellLengthLimit / 2) ?
                `${sectionHeadingInANutshellLength}/${sectionHeadingInANutshellLengthLimit}` : ""
            }
          />

        </Box>

      </Box>

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >

        <Box
          sx={{
            display: "flex",
            borderRadius: "20px",
            backgroundColor: contentBackgroundColour,
            padding: "16px"
          }}
        >

          <BERScaleBeforeAndAfter
            beforeRating={dwelling.performance.rating}
            afterRating={scenarioRating}
          />

        </Box>

      </Box>

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >
        <Box
          sx={{
            backgroundColor: "#16a34a",
            borderRadius: "20px",
            padding: "8px"
          }}
        >

          <Typography
            sx={{
              color: theme.palette.common.white,
              textAlign: 'center',
              fontWeight: "bold"
            }}
          >
            Total SEAI grant: €{grantTotal}
          </Typography>


        </Box>

      </Box>

    </DialogContent>
  );
}