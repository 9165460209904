import { toast } from "react-toastify";

import { UNLIMITED_PAGE_SIZE } from "common/api/constants";
import { retrokitApi } from "common/api/retrokitApi";
import { getDateFromUTCSeconds } from "common/utils/date-utils";
import {
  paginatedDwellingsApiTransformer
} from "features/dwellings/api/transformers";


import type {
  QueryReturnValue
} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import type {
  FetchBaseQueryError, FetchBaseQueryMeta
} from "@reduxjs/toolkit/query/react";
import type {
  PaginatedQueryParameters, PaginatedResponse
} from "common/api/types";
import type { DwellingsListQueryParams } from "features/dwellings/api/types";
import type { Dwelling } from "features/dwellings/types";
import type {
  UpdateProjectScenarioRecommendationRequest,
  CreateProjectApiRequest,
  DeleteProjectApiRequest,
  GetProjectByIDParameters,
  GetProjectDwellingParameters,
  MinimalProjectApiResponse,
  ProjectApiResponse,
  RunProjectWebSocketResponse,
  RunProjectWebSocketRequest,
  UpdateProjectDwellingsRequest,
  UpdateProjectDetailsRequest,
  ExportSuperhomesResponse,
  ExportSuperhomesRequest
} from "features/projects/api/types";
import type { Project, MinimalProject } from "features/projects/types";
import type { MarkRequired } from "ts-essentials";


export const projectsApi = retrokitApi.injectEndpoints({
  endpoints: (builder) => ({

    createProject: builder.mutation<MinimalProject, CreateProjectApiRequest>({
      query: (arg) => ({
        url: `projects/`,
        method: "POST",
        body: arg
      }),
      invalidatesTags: [
        "Projects"
      ],
      transformResponse: (response: MinimalProjectApiResponse): MinimalProject => ({
        ...response,
        created_on: getDateFromUTCSeconds(parseInt(response.created_on, 10))
      })
    }),

    updateProjectDetails: builder.mutation<void, UpdateProjectDetailsRequest>({
      query: (arg) => ({
        url: `projects/${arg.id}/`,
        method: "PUT",
        params: {
          title: arg.title,
          description: arg.description
        }
      }),
      invalidatesTags: [
        "Project",
        "Projects"
      ]
    }),

    deleteProject: builder.mutation<MinimalProject, DeleteProjectApiRequest>({
      query: (arg) => ({
        url: `projects/${arg.id}/delete/`,
        method: "POST"
      }),
      invalidatesTags: [
        "Project",
        "Projects"
      ]
    }),

    getProjects: builder.query<PaginatedResponse<MinimalProject>,
      PaginatedQueryParameters>({
      query: ({ page = 1, size = UNLIMITED_PAGE_SIZE }) =>
        `projects/minimal/?page=${page}&size=${size}`,
      providesTags: ["Projects"],
      transformResponse: (
        response: PaginatedResponse<MinimalProjectApiResponse>
      ): PaginatedResponse<MinimalProject> => {
        const transformedResponse: PaginatedResponse<MinimalProject> = {
          ...response,
          results: []
        };

        // Convert string to date
        transformedResponse.results = response.results.map((project) => ({
          ...project,
          created_on: getDateFromUTCSeconds(parseInt(project.created_on, 10))
        }));

        return transformedResponse;
      }
    }),

    getProjectById: builder.query<Project, GetProjectByIDParameters>({
      query: ({ id }) => `projects/${id}/`,
      providesTags: ["Project"],
      transformResponse: (response: ProjectApiResponse): Project => ({
        ...response,
        created_on: getDateFromUTCSeconds(
          parseInt(response.created_on, 10)
        )
      })
    }),

    getProjectDwellings: builder.query<PaginatedResponse<Dwelling>,
      GetProjectDwellingParameters>({
      query: ({ id, page = 1, size = UNLIMITED_PAGE_SIZE }) =>
        `projects/minimal/${id}/dwellings/?page=${page}&size=${size}`,
      providesTags: ["ProjectDwellings"],
      transformResponse: paginatedDwellingsApiTransformer
    }),

    getDwellingsNotInProject: builder.query<PaginatedResponse<Dwelling>,
      MarkRequired<DwellingsListQueryParams, "not_in_project">>({
      query: (params) => ({
        url: `dwellings/minimal/`,
        method: "GET",
        params: {
          // TODO: Change this after pagination
          page: 1,
          size: UNLIMITED_PAGE_SIZE,
          ...params
        }
      }),
      providesTags: ["ProjectDwellings"],
      transformResponse: paginatedDwellingsApiTransformer
    }),

    updateProjectDwellings: builder.mutation<void, UpdateProjectDwellingsRequest>({
      query: ({ id, ...body }) => ({
        url: `projects/minimal/${id}/dwellings/`,
        method: "POST",
        body
      }),
      invalidatesTags: ["ProjectDwellings"]
    }),

    updateProjectScenarioRecommendation: builder.mutation<void, UpdateProjectScenarioRecommendationRequest>({
      query: (arg) => ({
        url: `projects/${arg.id}/recommended-scenarios/`,
        method: "PUT",
        body: {
          dwelling_id: arg.dwelling_id,
          scenario_id: arg.scenario_id,
          dwelling_type: arg.dwelling_type
        }
      }),
      invalidatesTags: [
        "Project", "ProjectDwellings"
      ]
    }),

    superhomesExport: builder.mutation<ExportSuperhomesResponse, ExportSuperhomesRequest>({
      query: ({ id, ...body }) => ({
        url: `projects/${id}/export/superhomes`,
        method: "POST",
        body
      })
    }),

    runProject: builder.query<RunProjectWebSocketResponse, RunProjectWebSocketRequest>({
      async queryFn(arg) {

        const socket = new WebSocket(
          `${
            process.env.REACT_APP_WEBSOCKET_BASE_URL ||
            "ws://api.".concat(window.location.host)
          }/ws/project/run/?token=${arg.token}`
        );

        const result = await new Promise<QueryReturnValue<boolean, FetchBaseQueryError, FetchBaseQueryMeta>>((resolve, reject) => {

          socket.onopen = () => {
            socket.send(
              JSON.stringify(
                {
                  project_id: arg.project.id
                }
              )
            );
            toast.dismiss();
            toast.info(
              `Generating results for ${arg.project.title}`,
              {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored",
                autoClose: false
              }
            );
          };

          socket.onmessage = (event: MessageEvent<unknown>) => {
            if (typeof event.data === "string") {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              const data = JSON.parse(event.data);
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              if (data.message) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
                resolve({ data: data.message });
              }
            }
          };

          socket.onerror = () => {
            socket.close();
            toast.dismiss();
            if (arg.project) {
              toast.error(
                `Error while generating results for ${arg.project.title}`,
                {
                  position: toast.POSITION.TOP_RIGHT,
                  theme: "colored"
                }
              );
            }
            reject();
          };

          socket.onclose = () => {
            toast.dismiss();
            toast.success(
              `Finished generating results for ${arg.project.title}`,
              {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored"
              }
            );

            // Leaving commenting out as probably not necessary
            // resolve({
            //   data: true
            // });
          };
        });

        return result.data ? {
          data: { success: result.data }
        } : {
          data: { success: false }
        };

      },
      async onQueryStarted(
        arg, {
          updateCachedData,
          queryFulfilled,
          dispatch
        }
      ) {
        // Ensure the cache is updated after each query is executed
        const response = await queryFulfilled;

        updateCachedData((draft) => {
          if (response.data && response.data.success) {
            draft.success = response.data.success;
          }
        });

        console.log("response = ", response)

        dispatch(
          projectsApi.util.invalidateTags(
            [
              "Project",
              "ProjectScenarios",
              "ProjectDwellings",
              "ProjectCosts"
            ]
          )
        );
      }
    })
  })
});

export const {
  useGetProjectsQuery,
  useGetProjectByIdQuery,
  useCreateProjectMutation,
  useUpdateProjectDetailsMutation,
  useDeleteProjectMutation,
  useGetProjectDwellingsQuery,
  useGetDwellingsNotInProjectQuery,
  useUpdateProjectDwellingsMutation,
  useLazyRunProjectQuery,
  useSuperhomesExportMutation,
  useUpdateProjectScenarioRecommendationMutation
} = projectsApi;
