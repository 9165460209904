import type { Dispatch, SetStateAction } from "react";
import React, { useCallback, useMemo, useState } from "react";

import MaterialTable from "@material-table/core";

import {
  Add as AddIcon,
  Edit as EditIcon,
  Person as PersonIcon,
  Remove as RemoveIcon
} from "@mui/icons-material";
import { colors, Zoom } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import type { PaperProps } from "@mui/material/Paper";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";

import { ConfirmationDialog } from "common/components/ConfirmationDialog";
import { RetroKitIcon } from "common/components/RetroKitIcon";
import { PROJECT_SCENARIO_TABLE_COLUMNS } from "common/constants/tables";

import type { Action, Column } from "@material-table/core";
import type { ProjectScenario } from "features/projectScenarios/types";


interface ProjectScenariosTableProps {
  isLoading: boolean;
  isProjectRunning: boolean;
  projectScenarios: Array<ProjectScenario>;
  onRemoveScenario: (scenario: ProjectScenario) => void;
  setEditingScenario: Dispatch<SetStateAction<ProjectScenario | undefined>>;
  setShowAddScenariosModal: Dispatch<SetStateAction<boolean>>;
  setShowEditScenariosModal: Dispatch<SetStateAction<boolean>>;
}

export function ProjectScenariosTable(
  {
    isLoading,
    isProjectRunning,
    onRemoveScenario,
    projectScenarios,
    setEditingScenario,
    setShowAddScenariosModal,
    setShowEditScenariosModal
  }: ProjectScenariosTableProps
) {

  const theme = useTheme();

  const [
    removeScenarioConfirmationDialogTitle,
    setRemoveScenarioConfirmationDialogTitle
  ] = useState<string>("");

  const [
    removeScenarioConfirmationDialogMessage,
    setRemoveScenarioConfirmationDialogMessage
  ] = useState<string>("");

  const [
    removeScenarioConfirmationDialogOpen,
    setRemoveScenarioConfirmationDialogOpen
  ] = useState<boolean>(false);

  const [
    selectedScenario, setSelectedScenario
  ] = useState<ProjectScenario>();

  const [
    confirmationDialogTitle, setConfirmationDialogTitle
  ] = useState<string>("");

  const [
    confirmationDialogMessage, setConfirmationDialogMessage
  ] = useState<string>("");

  const [
    confirmationDialogOpen, setConfirmationDialogOpen
  ] = useState<boolean>(false);

  const handleCloseRemoveScenarioConfirmationDialog = () => {
    setRemoveScenarioConfirmationDialogOpen(false);
  };

  const handleCloseConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
  };

  const handleEditRowClicked = useCallback(
    (scenario: ProjectScenario) => {
      setEditingScenario(scenario);
      setShowEditScenariosModal(true);
    },
    [
      setEditingScenario,
      setShowEditScenariosModal
    ]
  );

  const handleShowAddScenariosModal = useCallback(
    (show: boolean) => {
      setShowAddScenariosModal(show);
    },
    [
      setShowAddScenariosModal
    ]
  );

  const handleRemoveScenarioClicked = useCallback(
    (scenario: ProjectScenario) => {
      setSelectedScenario(scenario);
      setRemoveScenarioConfirmationDialogTitle(
        "Confirm remove scenario"
      );
      setRemoveScenarioConfirmationDialogMessage(
        "This will delete all existing results for this scenario"
      );
      setRemoveScenarioConfirmationDialogOpen(true);
    },
    []
  );

  const handleAvatarClick = useCallback(
    (title: string, message: string, show: boolean) => {
      setConfirmationDialogTitle(title);
      setConfirmationDialogMessage(message);
      setConfirmationDialogOpen(show);
    },
    [
      setConfirmationDialogTitle,
      setConfirmationDialogMessage,
      setConfirmationDialogOpen
    ]
  );

  const tableActions: Array<Action<ProjectScenario>> = useMemo(
    () => {

      const addIcon = () => {
        if (isLoading) {
          return <AddIcon
            sx={{
              color: theme.retrokitPalette.grey.main
            }}
          />;
        }
        return <AddIcon
          sx={{
            color: theme.retrokitPalette.green.dark
          }}
        />;
      };

      const editIcon = () => {
        if (isLoading) {
          return <EditIcon
            sx={{
              color: theme.retrokitPalette.grey.main
            }}
          />;
        }
        return <EditIcon
          sx={{
            color: theme.retrokitPalette.green.dark
          }}
        />;
      };

      const removeIcon = () => {
        if (isLoading) {
          return <RemoveIcon
            sx={{
              color: theme.retrokitPalette.grey.main
            }} />;
        }
        return <RemoveIcon
          sx={{
            color: theme.retrokitPalette.red.dark
          }}
        />;
      };

      return [
        {
          icon: addIcon,
          disabled: isProjectRunning,
          isFreeAction: true,
          tooltip: (() => {
            if (isProjectRunning) {
              return "Can't add scenarios while results are being generated";
            }
            return "Add scenario(s) to the project";
          })(),
          onClick: () => {
            handleShowAddScenariosModal(true);
          }
        },
        {
          icon: editIcon,
          disabled: isProjectRunning,
          isFreeAction: false,
          tooltip: (() => {
            if (isProjectRunning) {
              return "Can't edit scenario while results are being generated";
            }
            return "Edit scenario";
          })(),
          onClick: (event: any, rowData: ProjectScenario[] | ProjectScenario) => {
            if (Array.isArray(rowData)) {
              if (rowData.length > 0) {
                handleEditRowClicked(rowData[0]);
              }
            } else {
              handleEditRowClicked(rowData);
            }
          }
        },
        {
          icon: removeIcon,
          disabled: isProjectRunning,
          isFreeAction: false,
          tooltip: (() => {
            if (isProjectRunning) {
              return "Can't remove scenario while results are being generated";
            }
            return "Remove scenario from project";
          })(),
          onClick: (event: any, rowData: ProjectScenario[] | ProjectScenario) => {

            if (Array.isArray(rowData)) {
              if (rowData.length > 0) {
                handleRemoveScenarioClicked(rowData[0]);
              }
            } else {
              handleRemoveScenarioClicked(rowData);
            }
          }
        }
      ];
    },
    [
      handleEditRowClicked,
      handleRemoveScenarioClicked,
      handleShowAddScenariosModal,
      isLoading,
      isProjectRunning,
      theme.retrokitPalette.green.dark,
      theme.retrokitPalette.grey.main,
      theme.retrokitPalette.red.dark
    ]
  );

  const tableColumns: Array<Column<ProjectScenario>> = useMemo(
    () => [
      ...PROJECT_SCENARIO_TABLE_COLUMNS,
      {
        title: "Type",
        field: "type",
        align: "center",
        width: 50,
        cellStyle: {
          padding: "0 16px 0 16px"
        },
        render: (rowData) => {
          if (rowData.type === "retrokit") {
            return <Tooltip
              title="RetroKit scenario"
              arrow
              TransitionComponent={Zoom}
            >
              <Avatar
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  width: "36px",
                  height: "36px"
                }}
                aria-label="avatar-retrokit"
                onClick={() => {
                  handleAvatarClick(
                    "RetroKit Scenarios",
                    "These are default scenarios provided by RetroKit. " +
                    "You can only edit them " +
                    "within the scope of this project.",
                    true
                  );
                }}
              >
                <RetroKitIcon
                  iconLink="static/public/images/logos/rk-logo-transparent-dark-background.svg"
                />
              </Avatar>
            </Tooltip>;
          }
          return <Tooltip
            title="Custom scenario"
            arrow
            TransitionComponent={Zoom}
          >
            <Avatar
              sx={{
                backgroundColor: theme.retrokitPalette.indigo.main,
                width: "36px",
                height: "36px"
              }}
              aria-label="avatar-user"
              onClick={() => {
                handleAvatarClick(
                  "Custom Scenarios",
                  "These are scenarios that are created by a user - " +
                  "See the Scenarios section." +
                  "Edits made here only apply to this project.",
                  true
                );
              }}
            >
              <PersonIcon style={{ color: "#FFFFFF" }} />
            </Avatar>
          </Tooltip>;
        }
      }
    ],
    [
      handleAvatarClick,
      theme.palette.primary.main,
      theme.retrokitPalette.indigo.main
    ]
  );

  const TablePaperComponent = useCallback(
    (
      props: JSX.IntrinsicAttributes & PaperProps
    ) => <Paper
      elevation={0}
      sx={{
        borderWidth: "0px",
        borderRadius: "20px"
      }}
      {...props}
    />,
    []
  );

  return <Box
    sx={{
      height: "100%",
      width: "100%",
      padding: 0,
      margin: 0,
    }}
  >
    <MaterialTable
      title="Scenarios"
      actions={tableActions}
      columns={tableColumns}
      components={{
        Container: TablePaperComponent
      }}
      data={projectScenarios}
      isLoading={isLoading}
      localization={{
        body: {
          emptyDataSourceMessage: "No scenarios to display"
        }
      }}
      options={{
        actionsColumnIndex: -1,
        headerStyle: {
          backgroundColor: colors.amber["500"],
          color: theme.palette.common.white,
          fontWeight: "bold",
          padding: "0 16px 0 16px"
        },
        padding: "dense",
        paging: false,
        rowStyle: (rowData, index) => {

          if (rowData.type === "retrokit") {
            if (index % 2) {
              return {
                backgroundColor: colors.indigo["100"],
                fontSize: 14
              };
            }
            return {
              backgroundColor: colors.indigo["50"],
              fontSize: 14
            };
          }

          if (index % 2) {
            return {
              backgroundColor: colors.amber["100"],
              fontSize: 14
            };
          }
          return {
            backgroundColor: colors.amber["50"],
            fontSize: 14
          };
        },
        selection: false,
        showTitle: true
      }}
    />

    <ConfirmationDialog
      buttonText="Okay"
      message={confirmationDialogMessage}
      title={confirmationDialogTitle}
      onClose={handleCloseConfirmationDialog}
      onSubmit={() => {
        handleCloseConfirmationDialog();
      }}
      open={confirmationDialogOpen}
    />

    <ConfirmationDialog
      message={removeScenarioConfirmationDialogMessage}
      title={removeScenarioConfirmationDialogTitle}
      onClose={handleCloseRemoveScenarioConfirmationDialog}
      onSubmit={() => {
        if (selectedScenario) {
          onRemoveScenario(selectedScenario);
        }
        setSelectedScenario(undefined);
        handleCloseRemoveScenarioConfirmationDialog();
      }}
      open={removeScenarioConfirmationDialogOpen}
    />

  </Box>;
}