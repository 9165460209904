import React, { useCallback, useState } from "react";

import { format } from "date-fns";
import { useStore } from "react-redux";

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  TableChart as TableIcon,
  InfoOutlined as InfoOutlinedIcon
} from "@mui/icons-material";
import {
  colors,
  TextField, Zoom
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { ConfirmationDialog } from "common/components/ConfirmationDialog";
import { LoadingButton } from "common/components/LoadingButton";

import type { Dwelling } from "features/dwellings/types";
import type {
  DeleteProjectApiRequest,
  RunProjectWebSocketRequest,
  UpdateProjectDetailsRequest,
  ExportSuperhomesRequest
} from "features/projects/api/types";
import type { Project } from "features/projects/types";
import type { ProjectScenario } from "features/projectScenarios/types";


interface ProjectDetailProps {
  isProjectRunning: boolean;
  onDeleteProject: (requestArgs: DeleteProjectApiRequest) => void;
  onUpdateProjectDetails: (requestArgs: UpdateProjectDetailsRequest) => void;
  project: Project;
  projectDwellings: ReadonlyArray<Dwelling>;
  projectScenarios: Array<ProjectScenario>;
  runProject: (requestArgs: RunProjectWebSocketRequest) => void;
  superhomesExport: (requestArgs: ExportSuperhomesRequest) => void;
}

export function ProjectDetails(
  {
    isProjectRunning,
    onDeleteProject,
    onUpdateProjectDetails,
    project,
    projectDwellings,
    projectScenarios,
    runProject,
    superhomesExport
  }: ProjectDetailProps
) {
  const theme = useTheme();

  const store = useStore();

  const handleDeleteProject = useCallback(
    (projectToDelete: Project) => {
      onDeleteProject({
        id: projectToDelete.id
      });
    },
    [
      onDeleteProject
    ]
  );

  const handleUpdateProjectTitle = useCallback(
    (newTitle: string) => {
      onUpdateProjectDetails({
        id: project.id,
        title: newTitle
      });
    },
    [
      project,
      onUpdateProjectDetails
    ]
  );

  const handleUpdateProjectDescription = useCallback(
    (newDescription: string) => {
      onUpdateProjectDetails({
        id: project.id,
        description: newDescription
      });
    },
    [
      project,
      onUpdateProjectDetails
    ]
  );

  const handleRunProject = useCallback(
    () => {

      runProject({
        project,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
        token: store.getState().authentication.token
      });
    },
    [project, runProject, store]);

  const handleExportProject = useCallback(
    () => {
      superhomesExport(project);
    },
    [project, superhomesExport]);

  const [
    confirmationDialogTitle, setConfirmationDialogTitle
  ] = useState<string>("");

  const [
    confirmationDialogMessage, setConfirmationDialogMessage
  ] = useState<string>("");

  const [
    confirmationDialogOpen, setConfirmationDialogOpen
  ] = useState<boolean>(false);

  const [
    editingDescription, setEditingDescription
  ] = useState<boolean>(false);

  const [
    editingTitle, setEditingTitle
  ] = useState<boolean>(false);

  const handleCloseConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
  };

  const [
    deleteProjectConfirmationDialogOpen, setDeleteProjectConfirmationDialogOpen
  ] = useState<boolean>(false);

  const handleCloseDeleteProjectConfirmationDialog = () => {
    setDeleteProjectConfirmationDialogOpen(false);
  };

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 10
      }}
    >
      <CssBaseline />

      <AppBar
        elevation={1}
        sx={{
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: theme.palette.common.white,
          color: theme.palette.text.primary,
          position: "sticky"
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >

            <Typography
              variant="h6"
              style={{
                fontSize: "18px",
                fontWeight: 400,
                textOverflow: "ellipsis"
              }}
            >
              Project
            </Typography>

            <Box
              sx={{
                flex: 1
              }}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "right",
                padding: "8px"
              }}
            >
              {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                store.getState().user.can_view_superhomes_export === true || store.getState().user.is_staff === true ?
                  <Tooltip
                    title="Export"
                  >
                    <IconButton
                      aria-label="project-export"
                      onClick={handleExportProject}
                    >
                      <TableIcon
                        sx={{
                          color: theme.palette.primary.main
                        }}
                      />
                    </IconButton>
                  </Tooltip> : null
              }


              <Tooltip
                title="Details"
              >
                <IconButton
                  aria-label="project-info"
                  onClick={() => {
                    setConfirmationDialogTitle(
                      "Projects"
                    );
                    setConfirmationDialogMessage(
                      "A range of scenarios are modelled on collections of dwellings in projects. Click the \"Run\" button to generate results and analyse how each scenario performs for each dwelling."
                    );
                    setConfirmationDialogOpen(
                      true
                    );
                  }}
                >
                  <InfoOutlinedIcon
                    sx={{
                      color: theme.palette.primary.main
                    }}
                  />
                </IconButton>
              </Tooltip>

              <Tooltip
                title="Delete this project"
              >
                <IconButton
                  aria-label="delete-project"
                  onClick={() => {
                    setDeleteProjectConfirmationDialogOpen(
                      true
                    );
                  }}
                >
                  <DeleteIcon
                    sx={{
                      color: theme.palette.primary.main
                    }}
                  />
                </IconButton>
              </Tooltip>

              <Tooltip
                title="Generate results for every dwelling and scenario in this project"
              >
                <Box sx={{
                  paddingLeft: "12px",
                  paddingRight: "12px",
                  display: "flex",
                  alignItems: "center"
                }}
                >
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    loading={isProjectRunning}
                    onClick={handleRunProject}
                  >
                    {isProjectRunning ? "Running" : "Run"}
                  </LoadingButton>
                </Box>
              </Tooltip>
            </Box>

          </Box>

        </Toolbar>

      </AppBar>

      <Box
        sx={{
          width: "100%",
          paddingTop: "16px",
          paddingBottom: "16px",
          paddingLeft: "32px",
          paddingRight: "32px",
          display: "flex",
          flexDirection: "row",
          gap: "16px"
        }}
      >
        <Paper
          sx={{
            paddingTop: "8px",
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingBottom: "8px",
            borderWidth: "0px",
            borderRadius: "20px",
            flex: 1
          }}
          elevation={0}
        >

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "8px",
              gap: "8px"
            }}
          >

            {
              editingTitle ? <Box
                sx={{
                  flex: 1
                }}
              >
                <TextField
                  autoFocus
                  defaultValue={project.title}
                  fullWidth
                  id="project-title"
                  label="TITLE"
                  onBlur={(event) => {
                    handleUpdateProjectTitle(event.target.value);
                    setEditingTitle(false);
                  }}
                  variant="outlined"
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleUpdateProjectTitle((event.target as HTMLInputElement).value);
                      setEditingTitle(false);
                      event.preventDefault();
                    }
                  }}
                />
              </Box> : <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  borderTopWidth: "0px",
                  borderLeftWidth: "0px",
                  borderRightWidth: "0px",
                  borderBottomWidth: "1px",
                  borderBottomStyle: "solid",
                  borderBottomColor: colors.grey["300"]
                }}
              >

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >

                  <Typography
                    variant="subtitle1"
                    style={{
                      fontSize: "11px",
                      fontWeight: 500,
                      textTransform: "uppercase",
                      color: colors.grey["700"]
                    }}
                  >
                    Title
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    style={{
                      fontSize: "1rem",
                      fontWeight: 400
                    }}
                  >
                    {project.title}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "right"
                  }}
                >
                  <Tooltip
                    title="Edit project title"
                    arrow
                    TransitionComponent={Zoom}
                  >
                    <IconButton
                      aria-label="edit project title"
                      onClick={() => {
                        setEditingTitle(true);
                      }}
                    >
                      <EditIcon sx={{
                        color: theme.retrokitPalette.green.dark
                      }}
                      />
                    </IconButton>
                  </Tooltip>

                </Box>

              </Box>
            }

            {
              editingDescription ? <Box
                sx={{
                  paddingTop: "16px",
                  flex: 1
                }}
              >
                <TextField
                  autoFocus
                  defaultValue={project.description}
                  fullWidth
                  id="project-description"
                  label="DESCRIPTION"
                  onBlur={(event) => {
                    handleUpdateProjectDescription(event.target.value);
                    setEditingDescription(false);
                  }}
                  variant="outlined"
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleUpdateProjectDescription((event.target as HTMLInputElement).value);
                      setEditingDescription(false);
                      event.preventDefault();
                    }
                  }}
                />
              </Box> : <Box
                sx={{
                  flex: 1,
                  paddingTop: "16px",
                  display: "flex",
                  flexDirection: "row",
                  borderTopWidth: "0px",
                  borderLeftWidth: "0px",
                  borderRightWidth: "0px",
                  borderBottomWidth: "1px",
                  borderBottomStyle: "solid",
                  borderBottomColor: colors.grey["300"]
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >

                  <Typography
                    variant="subtitle1"
                    style={{
                      fontSize: "11px",
                      fontWeight: 500,
                      textTransform: "uppercase",
                      color: colors.grey["700"]
                    }}
                  >
                    Description
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    style={{
                      fontSize: "1rem",
                      fontWeight: 400
                    }}
                  >
                    {project.description}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "right"
                  }}
                >
                  <Tooltip
                    title="Edit project description"
                    arrow
                    TransitionComponent={Zoom}
                  >
                    <IconButton
                      aria-label="edit project description"
                      onClick={() => setEditingDescription(true)}
                    >
                      <EditIcon sx={{
                        color: theme.retrokitPalette.green.dark
                      }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            }
          </Box>

        </Paper>

        <Paper
          sx={{
            paddingTop: "8px",
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingBottom: "8px",
            borderWidth: "0px",
            borderRadius: "20px",
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
          elevation={0}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              border: 0,
              width: "100%"
            }}
          >

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                alignItems: "center"
              }}
            >
              <Typography
                variant="subtitle1"
                style={{
                  fontSize: "1rem",
                  fontWeight: 500,
                  textTransform: "uppercase",
                  color: colors.grey["700"],
                  textAlign: "right",
                  flex: 1
                }}
              >
                Scenarios
              </Typography>

              <Typography
                variant="subtitle1"
                style={{
                  fontSize: "0.875rem",
                  fontWeight: 400,
                  textAlign: "center",
                  flex: 1
                }}
              >
                {projectScenarios.length}
              </Typography>
            </Box>


            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                alignItems: "center",
                borderTop: `1px solid ${colors.grey["300"]}`
              }}
            >
              <Typography
                variant="subtitle1"
                style={{
                  fontSize: "1rem",
                  fontWeight: 500,
                  textTransform: "uppercase",
                  color: colors.grey["700"],
                  textAlign: "right",
                  flex: 1
                }}
              >
                Dwellings
              </Typography>

              <Typography
                variant="subtitle1"
                style={{
                  fontSize: "0.875rem",
                  fontWeight: 400,
                  textAlign: "center",
                  flex: 1
                }}
              >
                {projectDwellings.length}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                alignItems: "center",
                borderTop: `1px solid ${colors.grey["300"]}`
              }}
            >
              <Typography
                variant="subtitle1"
                style={{
                  fontSize: "1rem",
                  fontWeight: 500,
                  textTransform: "uppercase",
                  color: colors.grey["700"],
                  textAlign: "right",
                  flex: 1
                }}
              >
                Created
              </Typography>

              <Typography
                variant="subtitle1"
                style={{
                  fontSize: "0.875rem",
                  fontWeight: 400,
                  textAlign: "center",
                  flex: 1
                }}
              >
                {`${format(project.created_on, "do MMM yyyy")}`}
              </Typography>
            </Box>

          </Box>

        </Paper>


      </Box>

      <ConfirmationDialog
        buttonText="Okay"
        message={confirmationDialogMessage}
        title={confirmationDialogTitle}
        onClose={handleCloseConfirmationDialog}
        onSubmit={() => {
          handleCloseConfirmationDialog();
        }}
        open={confirmationDialogOpen}
      />

      <ConfirmationDialog
        message="This will also delete all generated results for dwellings and scenarios in this project"
        title="Delete Project"
        onClose={handleCloseDeleteProjectConfirmationDialog}
        onSubmit={() => {
          handleCloseDeleteProjectConfirmationDialog();
          handleDeleteProject(project);
        }}
        open={deleteProjectConfirmationDialogOpen}
      />
    </Box>
  );
}
