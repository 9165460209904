import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";

import {
  AddAPhoto as AddAPhotoIcon,
  Clear as ClearIcon
} from "@mui/icons-material";
import {
  colors,
  FormGroup,
  FormLabel
} from "@mui/material";
import Box from "@mui/material/Box";
import { red } from "@mui/material/colors";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { BERChevron } from "common/components/BERChevron";
import { DwellingMapImage } from "common/components/DwellingMapImage";
import iconTitleHome from "common/icons/icon-title-home.png";
import { 
  useHomeEnergyAssessment 
} from "features/homeEnergyAssessment/hooks/useHomeEnergyAssessment";

import type { Dwelling } from "features/dwellings/types";

interface HomeEnergyAssessmentDialogContent1Props {
  dwelling: Dwelling;
  contentBackgroundColour: string;
  inputError: boolean;
  setInputError: React.Dispatch<React.SetStateAction<boolean>>;
  handleHeaFieldBlur: (
    value: string | number | File,
    field: string
  ) => void;
}


export function HomeEnergyAssessmentDialogContent1(
  {
    dwelling,
    contentBackgroundColour,
    inputError,
    setInputError,
    handleHeaFieldBlur
  }: HomeEnergyAssessmentDialogContent1Props
) {

  const {
    homeEnergyAssessment
  } = useHomeEnergyAssessment();

  const [
    sectionHeadingYourHome,
    setSectionHeadingYourHome
  ] = useState<string>("");

  const [
    sectionHeadingYourHomeError,
    setSectionHeadingYourHomeError
  ] = useState<boolean>(false);

  const [
    sectionHeadingYourHomeLength,
    setSectionHeadingYourHomeLength
  ] = useState<number>(0);

  const sectionHeadingYourHomeLengthLimit = 100;

  const sectionHeadingYourHomeInputRef = useRef<HTMLInputElement>();

  const [
    dwellingDescriptor,
    setDwellingDescriptor
  ] = useState<string>("");

  const [
    dwellingMprn,
    setDwellingMprn
  ] = useState<number>(0);

  const [
    clientNames,
    setClientNames
  ] = useState<string>("");

  const [
    clientNamesError,
    setClientNamesError
  ] = useState<boolean>(false);

  const [
    clientNamesLength,
    setClientNamesLength
  ] = useState<number>(0);

  const clientNamesLengthLimit = 255;

  const clientNamesInputRef = useRef<HTMLInputElement>();

  const [
    street,
    setStreet
  ] = useState<string>("");

  const [
    streetError,
    setStreetError
  ] = useState<boolean>(false);

  const [
    streetLength,
    setStreetLength
  ] = useState<number>(0);

  const streetLengthLimit = 255;

  const streetInputRef = useRef<HTMLInputElement>();

  const [
    area,
    setArea
  ] = useState<string>("");

  const [
    areaError,
    setAreaError
  ] = useState<boolean>(false);

  const [
    areaLength,
    setAreaLength
  ] = useState<number>(0);

  const areaLengthLimit = 255;

  const areaInputRef = useRef<HTMLInputElement>();

  const [
    city,
    setCity
  ] = useState<string>("");

  const [
    cityError,
    setCityError
  ] = useState<boolean>(false);

  const [
    cityLength,
    setCityLength
  ] = useState<number>(0);

  const cityLengthLimit = 255;

  const cityInputRef = useRef<HTMLInputElement>();

  const [
    county,
    setCounty
  ] = useState<string>("");

  const [
    countyError,
    setCountyError
  ] = useState<boolean>(false);

  const [
    countyLength,
    setCountyLength
  ] = useState<number>(0);

  const countyLengthLimit = 100;

  const countyInputRef = useRef<HTMLInputElement>();

  const [
    eirCode,
    setEirCode
  ] = useState<string>("");

  const [
    eirCodeError,
    setEirCodeError
  ] = useState<boolean>(false);

  const [
    eirCodeLength,
    setEirCodeLength
  ] = useState<number>(0);

  const eirCodeLengthLimit = 20;

  const eirCodeInputRef = useRef<HTMLInputElement>();

  const [
    logoUrl,
    setLogoUrl
  ] = useState<string>("");

  const [
    dwellingImageUrl,
    setDwellingImageUrl
  ] = useState<string>("");

  const logoInputRef = useRef<HTMLInputElement>(null);

  const dwellingImageInputRef = useRef<HTMLInputElement>(null);

  useEffect(
    () => {

      if (
        sectionHeadingYourHomeError ||
        clientNamesError ||
        streetError ||
        areaError ||
        countyError ||
        eirCodeError
      ) {
        setInputError(true);
      }

      if (inputError) {
        if (
          !sectionHeadingYourHomeError &&
          !clientNamesError &&
          !streetError &&
          !areaError &&
          !countyError &&
          !eirCodeError
        ) {
          setInputError(false);
        }
      }

    },
    [
      areaError,
      clientNamesError,
      countyError,
      eirCodeError,
      inputError,
      setInputError,
      sectionHeadingYourHomeError,
      streetError
    ]
  );

  useEffect(
    () => {

      if (homeEnergyAssessment !== undefined) {

        setSectionHeadingYourHome(
          homeEnergyAssessment.section_heading_your_home
        );

        setSectionHeadingYourHomeLength(
          homeEnergyAssessment.section_heading_your_home !== null ?
            homeEnergyAssessment.section_heading_your_home.length : 0
        )

        setDwellingDescriptor(
          homeEnergyAssessment.dwelling_descriptor
        )

        setDwellingMprn(
          homeEnergyAssessment.dwelling_mprn
        )

        setClientNames(
          homeEnergyAssessment.client_names
        );

        setClientNamesLength(
          homeEnergyAssessment.client_names !== null ?
            homeEnergyAssessment.client_names.length : 0
        )

        setStreet(
          homeEnergyAssessment.dwelling_address_line1
        );

        setStreetLength(
          homeEnergyAssessment.dwelling_address_line1 !== null ?
            homeEnergyAssessment.dwelling_address_line1.length : 0
        )

        setArea(
          homeEnergyAssessment.dwelling_address_line2
        );

        setAreaLength(
          homeEnergyAssessment.dwelling_address_line2 !== null ?
            homeEnergyAssessment.dwelling_address_line2.length : 0
        )

        setCity(
          homeEnergyAssessment.dwelling_address_line3
        );

        setCityLength(
          homeEnergyAssessment.dwelling_address_line3 !== null ?
            homeEnergyAssessment.dwelling_address_line3.length : 0
        )

        setCounty(
          homeEnergyAssessment.dwelling_county
        );

        setCountyLength(
          homeEnergyAssessment.dwelling_county !== null ?
            homeEnergyAssessment.dwelling_county.length : 0
        )

        setEirCode(
          homeEnergyAssessment.dwelling_postcode
        );

        setEirCodeLength(
          homeEnergyAssessment.dwelling_postcode !== null ?
            homeEnergyAssessment.dwelling_postcode.length : 0
        )

        setLogoUrl(
          homeEnergyAssessment.logo.includes("http") ? homeEnergyAssessment.logo :
            `${process.env.REACT_APP_API_BASE_URL || ""}${homeEnergyAssessment.logo}`
        );

        setDwellingImageUrl(
          // eslint-disable-next-line no-nested-ternary
          homeEnergyAssessment.dwelling_image !== "" ?
            homeEnergyAssessment.dwelling_image.includes("http") ?
              homeEnergyAssessment.dwelling_image :
              `${process.env.REACT_APP_API_BASE_URL || ""}${homeEnergyAssessment.dwelling_image}` : ""
        );
      }


    },
    [
      homeEnergyAssessment
    ]
  );

  const handleFieldUpdated = useCallback(
    (
      value: string | number | File,
      field: string
    ) => {
      handleHeaFieldBlur(value, field);
    },
    [
      handleHeaFieldBlur
    ]
  );

  const theme = useTheme();

  return (
    <DialogContent
      sx={{
        width: "100%",
        flex: 1,
        overflowX: "hidden",
        overflowY: "hidden",
        padding: "0px 8px 0px 8px",
        gap: "2rem"
      }}
    >

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            borderRadius: "20px",
            backgroundColor: contentBackgroundColour,
            padding: "16px"
          }}
        >
          <img
            src={iconTitleHome}
            alt="Icon"
            style={{
              maxHeight: "48px"
            }}
          />

          <TextField
            error={sectionHeadingYourHomeError}
            variant="standard"
            fullWidth
            inputProps={{
              style: {
                fontSize: "1.4rem",
                backgroundColor: theme.palette.common.white
              }
            }}
            InputLabelProps={{
              style: {
                fontSize: "1.4rem"
              }
            }}
            FormHelperTextProps={{
              sx: {
                textAlign: "right",
                width: "100%"
              }
            }}
            value={sectionHeadingYourHome}
            sx={{
              display: "flex",
              alignItems: "center",
              marginLeft: "16px",
              color: colors.grey["800"]
            }}
            onBlur={(event) => {
              if (!inputError) {
                handleFieldUpdated(
                  event.target.value,
                  "section_heading_your_home"
                );
              }
            }}
            onChange={(event) => {
              const {
                value
              } = event.target;
              setSectionHeadingYourHomeLength(value.length);
              if (value.length > sectionHeadingYourHomeLengthLimit) {
                setSectionHeadingYourHomeError(true);
              } else if (sectionHeadingYourHomeError) {
                setSectionHeadingYourHomeError(false);
              }
              setSectionHeadingYourHome(value);
            }}
            helperText={
              sectionHeadingYourHomeLengthLimit - sectionHeadingYourHomeLength <= (sectionHeadingYourHomeLengthLimit / 2) ?
                `${sectionHeadingYourHomeLength}/${sectionHeadingYourHomeLengthLimit}` : ""
            }
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                if (!inputError) {
                  if (
                    clientNamesInputRef !== undefined &&
                    clientNamesInputRef.current !== undefined
                  ) {
                    clientNamesInputRef.current.focus()
                  }
                }
              }
            }}
            inputRef={sectionHeadingYourHomeInputRef}
          />

        </Box>

      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px",
          gap: "1.125rem"
        }}
      >

        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "row",
              sm: "row",
              md: "row",
              lg: "column",
              xl: "column"
            },
            gap: "1.125rem",
            flex: 1
          }}
        >

          <FormGroup
            sx={{
              flex: 1,
              border: "1px",
              borderRadius: "20px",
              backgroundColor: contentBackgroundColour,
              padding: "16px",
              gap: "1rem"
            }}
          >

            <FormLabel
              component="legend"
              sx={{
                paddingLeft: "12px"
              }}
            >
              {dwellingDescriptor}
            </FormLabel>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: {
                  xs: "1.6rem",
                  sm: "1.6rem",
                  md: "1.6rem",
                  lg: "1.6rem",
                  xl: "1.6rem"
                },
                marginTop: "20px"
              }}
            >

              <TextField
                error={clientNamesError}
                label="Client name(s)"
                size="small"
                variant="outlined"
                inputProps={{
                  style: {
                    backgroundColor: theme.palette.common.white
                  }
                }}
                FormHelperTextProps={{
                  sx: {
                    textAlign: "right"
                  }
                }}
                value={clientNames}
                onBlur={(event) => {
                  if (!inputError) {
                    handleFieldUpdated(
                      event.target.value,
                      "client_names"
                    );
                  }
                }}
                onChange={(event) => {
                  const {
                    value
                  } = event.target;
                  setClientNamesLength(value.length);
                  if (value.length > clientNamesLengthLimit) {
                    setClientNamesError(true);
                  } else if (clientNamesError) {
                    setClientNamesError(false);
                  }
                  setClientNames(value);
                }}
                helperText={
                  clientNamesLengthLimit - clientNamesLength <= (clientNamesLengthLimit / 2) ?
                    `${clientNamesLength}/${clientNamesLengthLimit}` : ""
                }
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    if (!inputError) {
                      if (
                        streetInputRef !== undefined &&
                        streetInputRef.current !== undefined
                      ) {
                        streetInputRef.current.focus()
                      }
                    }
                  }
                }}
                inputRef={clientNamesInputRef}
              />

              <TextField
                error={streetError}
                label="Street"
                size="small"
                variant="outlined"
                inputProps={{
                  style: {
                    backgroundColor: theme.palette.common.white
                  }
                }}
                FormHelperTextProps={{
                  sx: {
                    textAlign: "right"
                  }
                }}
                value={street}
                onBlur={(event) => {
                  if (!inputError) {
                    handleFieldUpdated(
                      event.target.value,
                      "dwelling_address_line1"
                    );
                  }
                }}
                onChange={(event) => {
                  const {
                    value
                  } = event.target;
                  setStreetLength(value.length);
                  if (value.length > streetLengthLimit) {
                    setStreetError(true);
                  } else if (streetError) {
                    setStreetError(false);
                  }
                  setStreet(value);
                }}
                helperText={
                  streetLengthLimit - streetLength <= (streetLengthLimit / 2) ?
                    `${streetLength}/${streetLengthLimit}` : ""
                }
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    if (!inputError) {
                      if (
                        areaInputRef !== undefined &&
                        areaInputRef.current !== undefined
                      ) {
                        areaInputRef.current.focus()
                      }
                    }
                  }
                }}
                inputRef={streetInputRef}
              />

              <TextField
                error={areaError}
                label="Area"
                size="small"
                variant="outlined"
                inputProps={{
                  style: {
                    backgroundColor: theme.palette.common.white
                  }
                }}
                FormHelperTextProps={{
                  sx: {
                    textAlign: "right"
                  }
                }}
                value={area}
                onBlur={(event) => {
                  if (!inputError) {
                    handleFieldUpdated(
                      event.target.value,
                      "dwelling_address_line2"
                    );
                  }
                }}
                onChange={(event) => {
                  const {
                    value
                  } = event.target;
                  setAreaLength(value.length);
                  if (value.length > areaLengthLimit) {
                    setAreaError(true);
                  } else if (areaError) {
                    setAreaError(false);
                  }
                  setArea(value);
                }}
                helperText={
                  areaLengthLimit - areaLength <= (areaLengthLimit / 2) ?
                    `${areaLength}/${areaLengthLimit}` : ""
                }
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    if (!inputError) {
                      if (
                        cityInputRef !== undefined &&
                        cityInputRef.current !== undefined
                      ) {
                        cityInputRef.current.focus()
                      }
                    }
                  }
                }}
                inputRef={areaInputRef}
              />

              <TextField
                error={cityError}
                label="City"
                size="small"
                variant="outlined"
                inputProps={{
                  style: {
                    backgroundColor: theme.palette.common.white
                  }
                }}
                FormHelperTextProps={{
                  sx: {
                    textAlign: "right"
                  }
                }}
                value={city}
                onBlur={(event) => {
                  if (!inputError) {
                    handleFieldUpdated(
                      event.target.value,
                      "dwelling_address_line3"
                    );
                  }
                }}
                onChange={(event) => {
                  const {
                    value
                  } = event.target;
                  setCityLength(value.length);
                  if (value.length > cityLengthLimit) {
                    setCityError(true);
                  } else if (cityError) {
                    setCityError(false);
                  }
                  setCity(value);
                }}
                helperText={
                  cityLengthLimit - cityLength <= (cityLengthLimit / 2) ?
                    `${cityLength}/${cityLengthLimit}` : ""
                }
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    if (!inputError) {
                      if (
                        countyInputRef !== undefined &&
                        countyInputRef.current !== undefined
                      ) {
                        countyInputRef.current.focus()
                      }
                    }
                  }
                }}
                inputRef={cityInputRef}
              />

              <TextField
                error={countyError}
                label="County"
                size="small"
                variant="outlined"
                inputProps={{
                  style: {
                    backgroundColor: theme.palette.common.white
                  }
                }}
                FormHelperTextProps={{
                  sx: {
                    textAlign: "right"
                  }
                }}
                value={county}
                onBlur={(event) => {
                  if (!inputError) {
                    handleFieldUpdated(
                      event.target.value,
                      "dwelling_county"
                    );
                  }
                }}
                onChange={(event) => {
                  const {
                    value
                  } = event.target;
                  setCountyLength(value.length);
                  if (value.length > countyLengthLimit) {
                    setCountyError(true);
                  } else if (countyError) {
                    setCountyError(false);
                  }
                  setCounty(value);
                }}
                helperText={
                  countyLengthLimit - countyLength <= (countyLengthLimit / 2) ?
                    `${countyLength}/${countyLengthLimit}` : ""
                }
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    if (!inputError) {
                      if (
                        eirCodeInputRef !== undefined &&
                        eirCodeInputRef.current !== undefined
                      ) {
                        eirCodeInputRef.current.focus()
                      }
                    }
                  }
                }}
                inputRef={countyInputRef}
              />

              <TextField
                error={eirCodeError}
                label="Eircode"
                size="small"
                variant="outlined"
                inputProps={{
                  style: {
                    backgroundColor: theme.palette.common.white
                  }
                }}
                FormHelperTextProps={{
                  sx: {
                    textAlign: "right"
                  }
                }}
                value={eirCode}
                onBlur={(event) => {
                  if (!inputError) {
                    handleFieldUpdated(
                      event.target.value,
                      "dwelling_postcode"
                    );
                  }
                }}
                onChange={(event) => {

                  const {
                    value
                  } = event.target;

                  setEirCodeLength(value.length);

                  if (value.length > eirCodeLengthLimit) {
                    setEirCodeError(true);
                  } else if (eirCodeError) {
                    setEirCodeError(false);
                  }
                  setEirCode(value);
                }}
                helperText={
                  eirCodeLengthLimit - eirCodeLength <= (eirCodeLengthLimit / 2) ?
                    `${eirCodeLength}/${eirCodeLengthLimit}` : ""
                }
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    if (!inputError) {
                      if (
                        eirCodeInputRef !== undefined &&
                        eirCodeInputRef.current !== undefined
                      ) {
                        eirCodeInputRef.current.blur()
                      }
                    }
                  }
                }}
                inputRef={eirCodeInputRef}
              />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "4rem"
                }}
              >

                <Box
                  sx={{
                    flex: 0.7,
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    marginLeft: "0.7rem"
                  }}
                >
                  <Box
                    sx={{
                      paddingLeft: "4px"
                    }}
                  >

                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 400,
                        fontSize: "0.8rem",
                        color: colors.grey["600"]
                      }}
                    >
                      MPRN
                    </Typography>

                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      paddingLeft: "4px"
                    }}
                  >

                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 400,
                        fontSize: "1rem",
                        color: colors.grey["800"]
                      }}
                    >
                      {dwellingMprn}
                    </Typography>

                  </Box>

                </Box>

                <Box
                  sx={{
                    flex: 0.3,
                    display: "flex"
                  }}
                >

                  <BERChevron
                    rating={dwelling.performance.rating}
                  />

                </Box>

              </Box>

            </Box>

          </FormGroup>

        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            gap: "16px"
          }}
        >

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderWidth: "2px",
              borderColor: contentBackgroundColour,
              backgroundColor: contentBackgroundColour,
              borderRadius: "20px",
              borderStyle: "solid",
              height: "100%",
              width: "100%",
              padding: "8px",
              position: "relative"
            }}
            onClick={() => {
              if (logoInputRef.current !== null) {
                logoInputRef.current.click();
              }
            }}
          >
            <img
              src={logoUrl}
              alt={`Can't display logo: ${logoUrl}`}
              style={{
                maxHeight: "9.4rem",
                width: "auto",
                height: "auto",
                maxWidth: "100%"
              }}
            />

            <input
              alt="Logo"
              ref={logoInputRef}
              style={{
                display: "none"
              }}
              type="file"
              accept=".jpg,.jpeg,.svg,.png,.gif"
              onChange={(event) => {

                const {
                  files
                } = event.target;

                if (files !== null && files.length > 0) {
                  handleFieldUpdated(
                    files[0],
                    "logoFile"
                  );
                  const reader = new FileReader();
                  reader.onload = (e) => {
                    if (e.target) {
                      if (e.target.result) {
                        setLogoUrl(
                          String(e.target.result)
                        );
                      }
                    }
                  };
                  reader.readAsDataURL(files[0]);
                }
              }}
            />

            <Box
              sx={{
                borderWidth: "2px",
                borderColor: contentBackgroundColour,
                borderRadius: "20px",
                borderStyle: "solid",
                backgroundColor: contentBackgroundColour,
                position: "absolute",
                top: 0,
                left: "90%"
              }}
            >
              <IconButton
                aria-label="Add logo image"
                onClick={(event) => {
                  event.preventDefault();
                }}
              >
                <AddAPhotoIcon
                  color="secondary"
                />
              </IconButton>
            </Box>

          </Box>

          <Box
            sx={{
              flex: 1,
              borderWidth: "2px",
              borderColor: contentBackgroundColour,
              borderRadius: "20px",
              borderStyle: "solid",
              backgroundColor: contentBackgroundColour,
              padding: "8px",
              position: "relative"
            }}
            onClick={() => {
              if (dwellingImageInputRef.current !== null) {
                dwellingImageInputRef.current.click();
              }
            }}
          >

            <Box
              sx={{
                width: "416px",
                height: "350px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >

              {
                dwellingImageUrl !== "" ? <img
                  src={dwellingImageUrl}
                  alt={`Can't display dwelling: ${dwellingImageUrl}`}
                  style={{
                    width: "auto",
                    height: "auto",
                    maxWidth: "100%",
                    maxHeight: "100%"
                  }}
                /> : <DwellingMapImage
                  dwelling={dwelling}
                  width={416}
                  height={350}
                  borderRadius="20px"
                />
              }

              <input
                alt="Dwelling image"
                ref={dwellingImageInputRef}
                style={{
                  display: "none"
                }}
                type="file"
                accept=".jpg,.jpeg,.svg,.png,.gif"
                onChange={(event) => {

                  const {
                    files
                  } = event.target;

                  if (files !== null && files.length > 0) {
                    handleFieldUpdated(
                      files[0],
                      "dwellingImageFile"
                    );
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      if (e.target) {
                        if (e.target.result) {
                          setDwellingImageUrl(
                            String(e.target.result)
                          );
                        }
                      }
                    };
                    reader.readAsDataURL(files[0]);
                  }
                }}
              />

            </Box>

            <Box
              sx={{
                borderWidth: "2px",
                borderColor: contentBackgroundColour,
                borderRadius: "20px",
                borderStyle: "solid",
                backgroundColor: contentBackgroundColour,
                position: "absolute",
                top: 0,
                left: "90%"
              }}
            >
              {
                dwellingImageUrl !== "" ? <IconButton
                  aria-label="Remove dwelling image"
                  onClick={(event) => {
                    event.stopPropagation();

                    setDwellingImageUrl("");

                    handleFieldUpdated(
                      "",
                      "dwelling_image"
                    );
                  }}
                >
                  <ClearIcon
                    sx={{
                      color: red[500]
                    }}
                  />
                </IconButton> : <IconButton
                  aria-label="Add dwelling image"
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                >
                  <AddAPhotoIcon
                    color="secondary"
                  />
                </IconButton>
              }
            </Box>

          </Box>

        </Box>

      </Box>

    </DialogContent>
  );
}