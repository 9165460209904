import React from "react";

import mapboxgl from "mapbox-gl";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { 
  Link, 
  Route, 
  Switch, 
  withRouter 
} from "react-router-dom";

import AccountCircle from "@mui/icons-material/AccountCircle";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Dns from "@mui/icons-material/Dns";
import HelpIcon from "@mui/icons-material/Help";
import MenuIcon from "@mui/icons-material/Menu";
import PublishIcon from "@mui/icons-material/Publish";
import { styled } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { RETRY_STATUSES } from "common/assets/definitions/task_statuses";
import Logo from "common/assets/images/logo.png";
import {
  RetroKitRoutes,
  PARAM_SCENARIO_ID
} from "common/constants/retroKitRoutes";
import { withMaterialUserInterfaceTheme } from "common/hocs/theme";
import { ProjectsPage } from "pages/ProjectsPage";
import { ScenariosPage } from "pages/ScenariosPage";
import { logUserOut } from "v1/actions/authentication";
import { getGeoStock } from "v1/actions/dashboard";
import {
  addUpload,
  clearUploadTasks,
  getAddUploadTaskResult
} from "v1/actions/uploads";
import {
  getUser
} from "v1/actions/user";
import {
  ComponentToUpdateBreadCrumb
} from "v1/components/global/ComponentToUpdateBreadCrumb";
import UserActivityListener from "v1/components/shared/UserActivityListener";
import UploadProgressTracker from "v1/components/uploads/progress_tracker/UploadProgressTracker";


import Dashboard from "../dashboard/Dashboard";
import Uploads from "../uploads/Uploads";


const drawerWidth = 240;


mapboxgl.accessToken =
  "pk.eyJ1Ijoid2FsdGVyb2dyYWR5IiwiYSI6ImNrNjUweW4zMjEyYnEzb3Exc2o2MXAza3IifQ.5-uDFG7waKLr8RUizFyCbQ";


const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme)
    })
  })
);


const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

class RetroKit extends React.Component {
  constructor(props) {
    super(props);

    this.uploadPollingInterval = 1000;

    this.state = {

      navDrawerOpen: false,
      navDrawerSelectedIndex: 0,

      userButtonAnchorEl: null,
      helpButtonAnchorEl: null,
      errorPopUpAnchorEl: null,

      toolbarTitle: "RetroKit",

      uploading: false,
      uploadProgress: 0,
      uploadProgressBuffer: 0,
      uploadTaskCompleted: false,
      uploadTaskCancelled: false,

      showWelcome: false,

      projectsMenuShowing: false,

      scenariosMenuShowing: false
    };

    this.filesAdded = this.filesAdded.bind(this);

    this.setDrawerSelectedIndex = this.setDrawerSelectedIndex.bind(this);

    this.setToolbarTitle = this.setToolbarTitle.bind(this);

    this.setShowWelcome = this.setShowWelcome.bind(this);

    this.setProjectsMenuShowing = this.setProjectsMenuShowing.bind(this);
    this.setScenariosMenuShowing = this.setScenariosMenuShowing.bind(this);
  }

  handleDrawerChange(newState) {
    this.setState({ navDrawerOpen: newState });
  }

  handleDrawerIndexChange(newIndex) {
    this.setState({ navDrawerSelectedIndex: newIndex, navDrawerOpen: false });
  }

  handleErrorPopoverOpen(event) {
    this.setState({ errorPopUpAnchorEl: event.currentTarget });
  }

  handleErrorPopoverClose() {
    this.setState({ errorPopUpAnchorEl: null });
  }

  setDrawerSelectedIndex(newIndex) {
    this.setState({ navDrawerSelectedIndex: newIndex });
  }

  setToolbarTitle(newTitle) {
    this.setState({ toolbarTitle: newTitle });
  }

  setShowWelcome(show) {
    this.setState({ showWelcome: show });
  }

  setProjectsMenuShowing(showing) {
    this.setState({ projectsMenuShowing: showing });
  }

  setScenariosMenuShowing(showing) {
    this.setState({ scenariosMenuShowing: showing });
  }

  setUploadProgress(progress) {
    this.setState({ uploadProgress: progress });
  }

  async filesAdded(files) {

    this.setState({
      uploadProgressBuffer: 0,
      uploadProgress: 0,
      uploading: true
    });

    if (
      this.props.uploads.uploadTaskList.length > 0 ||
      this.props.uploads.errorsList.length > 0
    ) {
      this.props.clearUploadTasks();
    }

    for (let i = 0; i < files.length; i++) {
      if (!this.state.uploadTaskCancelled) {
        const file = files[i];

        const progress = ((i + 1) / files.length) * 100;

        this.setState({
          uploadingDescription: file.name,
          uploadTaskCompleted: false
        });

        const formData = new FormData();

        formData.append("file", file, file.name);

        const addUploadResponse = await this.props.addUpload(formData);

        this.setState({
          uploadProgressBuffer: progress
        });

        await new Promise((resolve) =>
          setTimeout(resolve, this.uploadPollingInterval)
        );

        if (!addUploadResponse.error) {
          let uploadTaskResponse = { ...addUploadResponse.uploadTask };
          do {
            uploadTaskResponse = await this.props.getAddUploadTaskResult(
              addUploadResponse.uploadTask.task_id
            );

            if (
              RETRY_STATUSES.includes(uploadTaskResponse.uploadTask.task_status)
            ) {
              await new Promise((resolve) =>
                setTimeout(resolve, this.uploadPollingInterval)
              );
            }
          } while (
            RETRY_STATUSES.includes(uploadTaskResponse.uploadTask.task_status)
            );
        }

        this.setState({
          uploadProgress: progress,
          uploadTaskCompleted: true
        });
      } else {
        this.props.clearUploadTasks();
      }
    }

    this.setState({ uploading: false });

    this.props.getGeoStock();
  }

  componentDidMount() {
    this.props.getUser();
  }

  render() {
    const { theme } = this.props;

    return (
      <Box
        id="retrokit"
        sx={{
          height: "100%",
          width: "100%",
          display: "flex"
        }}
      >
        <ComponentToUpdateBreadCrumb />

        <UserActivityListener />

        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
          }}
        >
          <CssBaseline />

          <AppBar
            position="fixed"
            open={this.state.navDrawerOpen}
          >
            <Toolbar>

              <Tooltip
                title="Open Navigation Menu"
                aria-label="navigation-drawer-button"
              >

                <IconButton
                  aria-label="open navigation drawer"
                  color="inherit"
                  onClick={() =>
                    this.handleDrawerChange(!this.state.navDrawerOpen)
                  }
                  edge="start"
                  sx={{
                    marginRight: "8px",
                    ...(this.state.navDrawerOpen && { display: "none" })
                  }}
                >

                  <MenuIcon />

                </IconButton>

              </Tooltip>

              <Tooltip
                title="Homepage"
                aria-label="retrokit"
              >
                <Link
                  style={{
                    color: "white",
                    textDecoration: "none"
                  }}
                  to="/"
                  onClick={
                    () => this.handleDrawerIndexChange(0)
                  }
                >
                  <IconButton
                    color="inherit"
                  >
                    <img
                      src={Logo}
                      alt="RK Logo"
                      style={{
                        maxWidth: 48,
                        maxHeight: 48
                      }}
                    />
                    <Typography
                      variant="h5"
                      noWrap
                      component="div"
                    >
                      RetroKit
                    </Typography>
                  </IconButton>
                </Link>
              </Tooltip>

              <Box
                sx={{
                  flexGrow: 1
                }}
              />

              <Box>
                <Tooltip
                  title="Help"
                  aria-label="username"
                >
                  <IconButton
                    aria-describedby="helpPopOver"
                    onClick={(event) =>
                      this.setState(
                        {
                          helpButtonAnchorEl: event.currentTarget
                        }
                      )
                    }
                    color="inherit"
                  >
                    <HelpIcon />
                  </IconButton>
                </Tooltip>

                <Popover
                  id="helpPopOver"
                  open={Boolean(this.state.helpButtonAnchorEl)}
                  anchorEl={this.state.helpButtonAnchorEl}
                  onClose={() => this.setState({ helpButtonAnchorEl: null })}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                  }}
                >
                  <Box
                    sx={{
                      padding: theme.spacing(2)
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingBottom: theme.spacing(2)
                      }}
                    >
                      <img
                        src={Logo}
                        alt="RK Logo"
                        style={{
                          maxWidth: 40,
                          maxHeight: 40
                        }}
                      />
                      <Typography
                        variant="h6"
                        noWrap
                        sx={{
                          paddingLeft: "8px",
                          fontSize: "1.05rem"
                        }}
                      >
                        RetroKit
                      </Typography>
                    </Box>

                    <Typography
                      sx={{
                        padding: theme.spacing(1)
                      }}
                      variant="subtitle2"
                      noWrap
                    >
                      Looking for assistance?
                    </Typography>

                    <Typography
                      sx={{
                        padding: theme.spacing(1)
                      }}
                      variant="body2"
                    >
                      <Link
                        to="#"
                        href="#"
                        onClick={
                          () => {
                            window.open(
                              "https://documentation.retrokit.eu/books/retrokit-user-guide",
                              "_blank"
                            ).focus();
                          }
                        }
                      >
                        Documentation
                      </Link>
                    </Typography>

                    <Typography
                      sx={{
                        padding: theme.spacing(1)
                      }}
                      variant="body2"
                    >
                      Username: user@retrokit.docs
                    </Typography>

                    <Typography
                      sx={{
                        padding: theme.spacing(1)
                      }}
                      variant="body2"
                    >
                      Password: user@retrokit.docs
                    </Typography>

                    <Typography
                      sx={{
                        padding: theme.spacing(1)
                      }}
                      variant="body2"
                    >
                      Send queries to{" "}
                      <Link
                        href="mailto:help@retrokit.eu"
                      >
                        help@retrokit.eu
                      </Link>
                    </Typography>
                  </Box>

                  <div
                    style={{
                      width: "100%",
                      height: 1,
                      backgroundColor: theme.palette.text.disabled
                    }}
                  />

                  <Button
                    sx={{
                      width: "100%"
                    }}
                    onClick={() => {
                      this.setShowWelcome(true);
                      this.setState({ helpButtonAnchorEl: null });
                    }}
                  >
                    Show welcome screen
                  </Button>
                </Popover>
              </Box>

              {this.props.authentication.isAuthenticated && (
                <div>
                  <Tooltip
                    title={this.props.user.username}
                    aria-label="username"
                  >
                    <IconButton
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={(event) =>
                        this.setState({
                          userButtonAnchorEl: event.currentTarget
                        })
                      }
                      color="inherit"
                    >
                      <AccountCircle />
                    </IconButton>
                  </Tooltip>

                  <Menu
                    id="user-menu-appbar"
                    anchorEl={this.state.userButtonAnchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    open={Boolean(this.state.userButtonAnchorEl)}
                    onClose={() => this.setState({ userButtonAnchorEl: null })}
                  >
                    <MenuItem onClick={this.props.logUserOut}>Log out</MenuItem>
                  </Menu>
                </div>
              )}
            </Toolbar>
          </AppBar>

          <Drawer
            variant="permanent"
            open={this.state.navDrawerOpen}
          >
            <DrawerHeader
              onClick={
                () => this.handleDrawerChange(!this.state.navDrawerOpen)
              }
            >
              <Typography
                variant="h6"
                noWrap
                sx={{
                  paddingLeft: "16px"
                }}
              >
                Navigation
              </Typography>

              <Box
                sx={{
                  flex: 1
                }}
              />

              <Tooltip
                title="Close Navigation Menu"
              >
                <IconButton>
                  {
                    theme.direction === "ltr" ?
                      <ChevronLeftIcon />
                      : <ChevronRightIcon />
                  }
                </IconButton>
              </Tooltip>

            </DrawerHeader>
            <Divider />
            <List>
              <Tooltip
                title="Stock"
                placement="right"
                aria-label="stock"
              >
                <Link
                  style={{
                    color: "black",
                    textDecoration: "none"
                  }}
                  to="/"
                  onClick={
                    () => this.handleDrawerIndexChange(0)
                  }
                >
                  <ListItem
                    disablePadding
                    key="Stock"
                  >
                    <ListItemButton
                      selected={
                        this.state.navDrawerSelectedIndex === 0
                      }
                    >
                      <ListItemIcon
                        aria-label="Stock"
                      >
                        <DashboardIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Stock"
                      />
                    </ListItemButton>

                  </ListItem>
                </Link>
              </Tooltip>

              {
                this.props.user.can_view_uploads === true &&
                (this.props.electoralDivisions === null ? (
                  <Tooltip
                    title="Uploads"
                    placement="right"
                    aria-label="uploads"
                  >
                    <ListItem
                      disablePadding
                      key="Uploads"
                    >
                      <ListItemButton>

                        <ListItemIcon
                          key="Uploads"
                        >
                          <LinearProgress
                            sx={{
                              width: "20px"
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary="Uploads"
                        />
                      </ListItemButton>

                    </ListItem>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title="Uploads"
                    placement="right"
                    aria-label="uploads"
                  >
                    <Link
                      style={{
                        color: "black",
                        textDecoration: "none"
                      }}
                      to="/uploads"
                      onClick={
                        () => this.handleDrawerIndexChange(1)
                      }
                    >
                      <ListItem
                        key="Uploads"
                        disablePadding
                      >
                        <ListItemButton
                          selected={this.state.navDrawerSelectedIndex === 1}
                        >
                          <ListItemIcon
                            aria-label="Uploads"
                          >
                            <PublishIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary="Uploads"
                          />
                        </ListItemButton>

                      </ListItem>
                    </Link>
                  </Tooltip>
                ))
              }

              {
                this.props.user.can_view_projects &&
                (this.props.electoralDivisions === null ? (
                  <Tooltip
                    title="Projects"
                    placement="right"
                    aria-label="projects"
                  >
                    <ListItem
                      key="Projects"
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          <LinearProgress
                            sx={{
                              width: "20px"
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary="Projects"
                        />
                      </ListItemButton>
                    </ListItem>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title="Projects"
                    placement="right"
                    aria-label="projects"
                  >
                    <Link
                      style={{
                        color: "black",
                        textDecoration: "none"
                      }}
                      to="/projects"
                      onClick={() => {
                        if (this.state.navDrawerSelectedIndex === 2) {
                          this.setState({ projectsMenuShowing: !this.state.projectsMenuShowing });
                        } else {
                          this.setState({ projectsMenuShowing: true });
                        }
                        this.handleDrawerIndexChange(2);
                      }}
                    >
                      <ListItem
                        key="Projects"
                        disablePadding
                      >
                        <ListItemButton
                          selected={this.state.navDrawerSelectedIndex === 2}
                        >
                          <ListItemIcon
                            aria-label="Projects"
                          >
                            <AccountTreeIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary="Projects"
                          />
                        </ListItemButton>
                      </ListItem>
                    </Link>
                  </Tooltip>
                ))
              }

              {
                this.props.user.can_view_projects &&
                (this.props.electoralDivisions === null ? (
                  <Tooltip
                    title="Scenarios"
                    placement="right"
                    aria-label="scenarios"
                  >
                    <ListItem
                      key="Scenarios"
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          <LinearProgress
                            sx={{
                              width: "20px"
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary="Scenarios"
                        />
                      </ListItemButton>
                    </ListItem>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title="Scenarios"
                    placement="right"
                    aria-label="scenarios"
                  >
                    <Link
                      style={{
                        color: "black",
                        textDecoration: "none"
                      }}
                      to="/scenarios"
                      onClick={() => {
                        if (this.state.navDrawerSelectedIndex === 3) {
                          this.setState({ scenariosMenuShowing: !this.state.scenariosMenuShowing });
                        } else {
                          this.setState({ scenariosMenuShowing: true });
                        }
                        this.handleDrawerIndexChange(3);
                      }}
                    >
                      <ListItem
                        key="Scenarios"
                        disablePadding
                      >
                        <ListItemButton
                          selected={this.state.navDrawerSelectedIndex === 3}
                        >
                          <ListItemIcon
                            aria-label="Scenarios"
                          >
                            <Dns />
                          </ListItemIcon>
                          <ListItemText
                            primary="Scenarios"
                          />
                        </ListItemButton>

                      </ListItem>
                    </Link>
                  </Tooltip>
                ))
              }
            </List>
          </Drawer>

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              padding: "0px",
              width: `calc(100% - ${drawerWidth}px)`,
              display: "flex",
              flexDirection: "column"
            }}
            onClick={
              () => this.handleDrawerChange(false)
            }
          >
            <DrawerHeader />

            <section
              style={{
                flex: 1
              }}
            >
              <Switch>
                <Route
                  exact
                  path={
                    RetroKitRoutes.DASHBOARD
                  }
                  render={
                    (props) => <Dashboard
                      {...props}
                      navDrawerOpen={this.state.navDrawerOpen}
                      setToolbarTitle={this.setToolbarTitle}
                      setDrawerSelectedIndex={this.setDrawerSelectedIndex}
                      showWelcome={this.state.showWelcome}
                      setShowWelcome={this.setShowWelcome}
                    />
                  }
                />

                {/* <Route
                  path="/account"
                  component={Account}
                />

                <Route
                  path="/dwellings/:dwellingId"
                  component={Dwelling}
                />

                <Route
                  path="/dwellings"
                  component={DwellingsFilter}
                />

                <Route
                  path="/guide"
                  component={Guide}
                /> */}

                <Route
                  exact
                  path={
                    RetroKitRoutes.PROJECTS
                  }
                  render={
                    (props) => <ProjectsPage
                      {...props}
                      projectsMenuShowing={this.state.projectsMenuShowing}
                      setProjectsMenuShowing={this.setProjectsMenuShowing}
                    />
                  }
                />

                <Route
                  exact
                  path={
                    RetroKitRoutes.SCENARIO
                  }
                  render={
                    (props) => <ScenariosPage
                      {...props}
                      scenariosMenuShowing={this.state.scenariosMenuShowing}
                      setScenariosMenuShowing={this.setScenariosMenuShowing}
                    />
                  }
                />

                <Route
                  exact
                  path={
                    RetroKitRoutes.UPLOADS
                  }
                  render={
                    (props) => <Uploads
                      {...props}
                      filesAdded={this.filesAdded}
                      navDrawerOpen={this.state.navDrawerOpen}
                      setToolbarTitle={this.setToolbarTitle}
                      setDrawerSelectedIndex={this.setDrawerSelectedIndex}
                    />
                  }
                />
              </Switch>
            </section>
          </Box>

          {
            this.props.uploads.uploadTaskList.length > 0 ||
            this.props.uploads.errorsList.length > 0 ? (
              <UploadProgressTracker
                uploadingDescription={this.state.uploadingDescription}
                uploadProgress={this.state.uploadProgress}
                uploadProgressBuffer={this.state.uploadProgressBuffer}
                uploadTaskCompleted={this.state.uploadTaskCompleted}
                uploadTaskCancelled={this.state.uploadTaskCancelled}
              />
            ) : null
          }


        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.authentication,
  geostock: state.dashboard.geostock,
  electoralDivisions: state.dashboard.electoralDivisions,
  projects: state.projects,
  user: state.user,
  uploads: state.uploads,
  breadcrumbs: state.globalLayout.breadcrumbs
});

const mapDispatchToProps = (dispatch) => ({
  addUpload: (formData) => dispatch(addUpload(formData)),
  clearUploadTasks: () => dispatch(clearUploadTasks()),
  getAddUploadTaskResult: (taskId) => dispatch(getAddUploadTaskResult(taskId)),
  getGeoStock: () => dispatch(getGeoStock()),
  getUser: () => dispatch(getUser()),
  logUserOut: () => dispatch(logUserOut())
});

RetroKit.propTypes = {
  theme: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withMaterialUserInterfaceTheme(
    withRouter(RetroKit)
  )
);