import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";


import MaterialTable from "@material-table/core";

import {
  colors
} from "@mui/material";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import type { PaperProps } from "@mui/material/Paper";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

import {
  DwellingAndScenarioPerformanceBanner
} from "common/components/DwellingAndScenarioPerformanceBanner";
import { HEA_TECHNICAL_TABLE_COLUMNS } from "common/constants/tables";
import iconTitleHomeBlank from "common/icons/icon-title-home-blank.png";
import {
  useHomeEnergyAssessment
} from "features/homeEnergyAssessment/hooks/useHomeEnergyAssessment";

import type { Column } from "@material-table/core";
import type { Dwelling } from "features/dwellings/types";
import type {
  HomeEnergyAssessmentDetailedTableData
} from "features/homeEnergyAssessment/types";


interface HomeEnergyAssessmentDialogContent7Props {
  contentBackgroundColour: string;
  pageBackgroundColour: string;
  dwelling: Dwelling;
  scenarioDetail: string;
  scenarioRating: string;
  inputError: boolean;
  setInputError: React.Dispatch<React.SetStateAction<boolean>>;
  handleHeaFieldBlur: (
    value: string | number | File,
    field: string
  ) => void;
}


export function HomeEnergyAssessmentDialogContent7(
  {
    dwelling,
    contentBackgroundColour,
    pageBackgroundColour,
    scenarioDetail,
    scenarioRating,
    inputError,
    setInputError,
    handleHeaFieldBlur
  }: HomeEnergyAssessmentDialogContent7Props
) {

  const {
    homeEnergyAssessment
  } = useHomeEnergyAssessment();

  const [
    sectionHeadingDetailedDesign,
    setSectionHeadingDetailedDesign
  ] = useState<string>("");

  const [
    sectionHeadingDetailedDesignError,
    setSectionHeadingDetailedDesignError
  ] = useState<boolean>(false);

  const [
    sectionHeadingDetailedDesignLength,
    setSectionHeadingDetailedDesignLength
  ] = useState<number>(0);

  const sectionHeadingDetailedDesignLengthLimit = 100;

  const sectionHeadingDetailedDesignInputRef = useRef<HTMLInputElement>();

  const [
    detailedDesignParagraph1,
    setDetailedDesignParagraph1
  ] = useState<string>("");

  const [
    detailedDesignParagraph1Error,
    setDetailedDesignParagraph1Error
  ] = useState<boolean>(false);

  const [
    detailedDesignParagraph1Length,
    setDetailedDesignParagraph1Length
  ] = useState<number>(0);

  const detailedDesignParagraph1LengthLimit = 600;

  const detailedDesignParagraph1InputRef = useRef<HTMLInputElement>();

  const [
    detailedDesignTableData,
    setDetailedDesignTableData
  ] = useState<HomeEnergyAssessmentDetailedTableData[]>([]);

  useEffect(
    () => {

      if (
        detailedDesignParagraph1Error ||
        sectionHeadingDetailedDesignError
      ) {
        setInputError(true);
      }

      if (inputError) {
        if (
          !detailedDesignParagraph1Error &&
          sectionHeadingDetailedDesignError
        ) {
          setInputError(false);
        }
      }

    },
    [
      detailedDesignParagraph1Error,
      sectionHeadingDetailedDesignError,
      inputError,
      setInputError
    ]
  );

  useEffect(
    () => {
      if (homeEnergyAssessment !== undefined) {
        setSectionHeadingDetailedDesign(
          homeEnergyAssessment.section_heading_detailed_design
        );

        setSectionHeadingDetailedDesignLength(
          homeEnergyAssessment.section_heading_detailed_design !== null ?
            homeEnergyAssessment.section_heading_detailed_design.length : 0
        );

        setDetailedDesignParagraph1(
          homeEnergyAssessment.detailed_design_paragraph_1
        );

        setDetailedDesignParagraph1Length(
          homeEnergyAssessment.detailed_design_paragraph_1 !== null ?
            homeEnergyAssessment.detailed_design_paragraph_1.length : 0
        );

        setDetailedDesignTableData(
          homeEnergyAssessment.detailed_table_data
        );
      }
    },
    [
      homeEnergyAssessment
    ]
  );

  const handleFieldUpdated = useCallback(
    (
      value: string | number | File,
      field: string
    ) => {
      handleHeaFieldBlur(value, field);
    },
    [
      handleHeaFieldBlur
    ]
  );

  const tableColumns: Array<Column<HomeEnergyAssessmentDetailedTableData>> = useMemo(
    () => [
      ...HEA_TECHNICAL_TABLE_COLUMNS
    ],
    []
  );

  const TablePaperComponent = useCallback(
    (
      props: JSX.IntrinsicAttributes & PaperProps
    ) => <Paper
      sx={{
        borderRadius: "20px 20px 0 0",
        backgroundColor: colors.grey["200"]
      }}
      variant="outlined"
      {...props}
    />,
    []
  );

  const theme = useTheme();

  return (
    <DialogContent
      sx={{
        width: "100%",
        flex: 1,
        overflowX: "hidden",
        overflowY: "hidden",
        padding: "0px 8px 0px 8px",
        gap: "2rem"
      }}
    >

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            borderRadius: "20px",
            backgroundColor: contentBackgroundColour,
            padding: "16px"
          }}
        >
          <img
            src={iconTitleHomeBlank}
            alt="Icon"
            style={{
              maxHeight: "48px"
            }}
          />

          <TextField
            error={sectionHeadingDetailedDesignError}
            variant="standard"
            fullWidth
            inputProps={{
              style: {
                fontSize: "1.4rem",
                backgroundColor: theme.palette.common.white
              }
            }}
            InputLabelProps={{
              style: {
                fontSize: "1.4rem"
              }
            }}
            FormHelperTextProps={{
              sx: {
                textAlign: "right",
                width: "100%"
              }
            }}
            value={sectionHeadingDetailedDesign}
            sx={{
              display: "flex",
              alignItems: "center",
              marginLeft: "16px",
              color: colors.grey["800"]
            }}
            onBlur={(event) => {
              if (!inputError) {
                handleFieldUpdated(
                  event.target.value,
                  "section_heading_detailed_design"
                );
              }
            }}
            onChange={(event) => {
              const {
                value
              } = event.target;
              setSectionHeadingDetailedDesignLength(value.length);
              if (value.length > sectionHeadingDetailedDesignLengthLimit) {
                setSectionHeadingDetailedDesignError(true);
              } else if (sectionHeadingDetailedDesignError) {
                setSectionHeadingDetailedDesignError(false);
              }
              setSectionHeadingDetailedDesign(value);
            }}
            helperText={
              sectionHeadingDetailedDesignLengthLimit - sectionHeadingDetailedDesignLength <= (sectionHeadingDetailedDesignLengthLimit / 2) ?
                `${sectionHeadingDetailedDesignLength}/${sectionHeadingDetailedDesignLengthLimit}` : ""
            }
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                if (!inputError) {
                  if (
                    detailedDesignParagraph1InputRef !== undefined &&
                    detailedDesignParagraph1InputRef.current !== undefined
                  ) {
                    detailedDesignParagraph1InputRef.current.focus();
                  }
                }
              }
            }}
            inputRef={sectionHeadingDetailedDesignInputRef}
          />

        </Box>

      </Box>

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >
        <DwellingAndScenarioPerformanceBanner
          dwellingDetail={String(dwelling.mprn)}
          dwellingRating={dwelling.performance.rating}
          scenarioDetail={scenarioDetail}
          scenarioRating={scenarioRating}
        />

      </Box>

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >
        <TextField
          error={detailedDesignParagraph1Error}
          label="Detailed design table"
          sx={{
            backgroundColor: pageBackgroundColour,
            width: "100%"
          }}
          multiline
          size="small"
          variant="outlined"
          InputProps={{
            sx: {
              backgroundColor: theme.palette.common.white
            }
          }}
          FormHelperTextProps={{
            sx: {
              textAlign: "right"
            }
          }}
          value={detailedDesignParagraph1}
          onBlur={(event) => {
            if (!inputError) {
              handleFieldUpdated(
                event.target.value,
                "detailed_design_paragraph_1"
              );
            }
          }}
          onChange={(event) => {
            const {
              value
            } = event.target;
            setDetailedDesignParagraph1Length(value.length);
            if (value.length > detailedDesignParagraph1LengthLimit) {
              setDetailedDesignParagraph1Error(true);
            } else if (detailedDesignParagraph1Error) {
              setDetailedDesignParagraph1Error(false);
            }
            setDetailedDesignParagraph1(value);
          }}
          helperText={
            detailedDesignParagraph1LengthLimit - detailedDesignParagraph1Length <= (detailedDesignParagraph1LengthLimit / 2) ?
              `${detailedDesignParagraph1Length}/${detailedDesignParagraph1LengthLimit}` : ""
          }
          inputRef={detailedDesignParagraph1InputRef}
        />

      </Box>

      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingBottom: "16px"
        }}
      >

        <MaterialTable
          columns={tableColumns}
          components={{
            Container: TablePaperComponent,
            Toolbar: () => null
          }}
          data={detailedDesignTableData}
          localization={{
            body: {
              emptyDataSourceMessage: "No measures to display",
              filterRow: {
                filterTooltip: "Filter"
              }
            }
          }}
          options={{
            idSynonym: "step",
            headerStyle: {
              color: theme.palette.common.black,
              padding: "6px 12px 6px 12px",
              height: "56px",
              fontWeight: 500,
              fontSize: "0.875rem"
            },
            padding: "dense",
            paging: false,
            rowStyle: () => ({
              fontWeight: 400,
              fontSize: "0.875rem",
              backgroundColor: theme.palette.common.white
            }),
            search: false,
            sorting: false,
            showTitle: false,
            tableLayout: "fixed",
            thirdSortClick: false
          }}
        />

      </Box>

    </DialogContent>
  );
}